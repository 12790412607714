var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "elevation-0",
          class: { selected: _vm.selected },
          attrs: { width: "100%" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0 ma-0 mb-2" },
            [
              _c(
                "v-row",
                [
                  _vm.isImage
                    ? [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("p", { staticClass: "caption" }, [
                              _vm._v(
                                " " + _vm._s(_vm.item.question.label) + " "
                              ),
                            ]),
                            _vm._l(_vm.options, function (option) {
                              return _c("v-checkbox", {
                                key: option.id,
                                attrs: {
                                  value: option.value,
                                  disabled: _vm.readOnly,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("img", {
                                            staticStyle: { width: "64px" },
                                            attrs: { src: option.value },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.selectedValues,
                                  callback: function ($$v) {
                                    _vm.selectedValues = $$v
                                  },
                                  expression: "selectedValues",
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ]
                    : [
                        _vm.item.question.showAllOptions
                          ? [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("p", { staticClass: "caption" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.item.question.label) +
                                        " "
                                    ),
                                  ]),
                                  _vm._l(_vm.options, function (option) {
                                    return _c("v-checkbox", {
                                      key: option.id,
                                      attrs: {
                                        value: option.value,
                                        label: option.label,
                                        disabled: _vm.readOnly,
                                      },
                                      model: {
                                        value: _vm.selectedValues,
                                        callback: function ($$v) {
                                          _vm.selectedValues = $$v
                                        },
                                        expression: "selectedValues",
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ]
                          : [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      slim: "",
                                      name: _vm.item.question.label,
                                      rules: {
                                        required: _vm.isRequired,
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.options,
                                                "item-text": "label",
                                                "item-value": "value",
                                                label: _vm.item.question.label,
                                                "error-messages": errors,
                                                success: valid,
                                                multiple: "",
                                                disabled: _vm.readOnly,
                                              },
                                              on: { change: _vm.onInputChange },
                                              model: {
                                                value: _vm.selectedValues,
                                                callback: function ($$v) {
                                                  _vm.selectedValues = $$v
                                                },
                                                expression: "selectedValues",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                      ],
                  _vm.showTextInput
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: _vm.item.question.label,
                              rules: "required",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: _vm.item.id,
                                          disabled: _vm.readOnly,
                                        },
                                        on: { input: _vm.onInputChange },
                                        model: {
                                          value: _vm.otherValue,
                                          callback: function ($$v) {
                                            _vm.otherValue = $$v
                                          },
                                          expression: "otherValue",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              994803265
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
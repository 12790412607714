<template>
  <v-card class="elevation-0 mx-auto mt-4" max-width="600" id="settingsPanel">
    <v-card-text class="elevation-0">
      <v-row>
        <v-col cols="12">
          <validation-provider
            slim
            v-slot="{ errors, valid }"
            name="Title"
            rules="required"
          >
            <v-text-field
              v-model="formDefinition.form.formDescription.title"
              label="Title"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              @input="$emit('input', formDefinition)"
            />
          </validation-provider>
        </v-col>

        <v-col cols="12">
          <validation-provider slim v-slot="{ errors, valid }" name="Title">
            <v-text-field
              v-model="formDefinition.form.description"
              label="Description"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              @input="$emit('input', formDefinition)"
            />
          </validation-provider>
        </v-col>

        <v-col cols="12">
          <validation-provider slim v-slot="{ errors, valid }" name="Folder">
            <v-text-field
              v-model="formDefinition.folder"
              label="Folder"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              @input="$emit('input', formDefinition)"
            />
          </validation-provider>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="formDefinition.form.formDescription.canEditAfterFinal"
            label="Editable after submitted final"
            @change="$emit('input', formDefinition)"
            class="mt-0 mb-0 pt-0 pb-0"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="formDefinition.form.formDescription.isAccordionDisplay"
            label="Display as accordion"
            @change="$emit('input', formDefinition)"
            class="mt-0 mb-0 pt-0 pb-0"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="formDefinition.form.formDescription.includeObjectId"
            label="Show ObjectID Form Submission Page"
            @change="$emit('input', formDefinition)"
            class="mt-0 mb-0 pt-0 pb-0"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="
              formDefinition.form.formDescription.showUtiliBotsOnFormSubmission
            "
            label="Show UtiliBots on Form Submission"
            @change="$emit('input', formDefinition)"
            class="mt-0 mb-0 pt-0 pb-0"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="formDefinition.organization_user_enabled"
            label="Organization User Enabled"
            @change="$emit('input', formDefinition)"
            class="mt-0 mb-0 pt-0 pb-0"
          ></v-switch>
        </v-col>

        <v-col cols="12">
          <v-switch
            v-model="formDefinition.site_contact_enabled"
            label="Site Contact Enabled"
            @change="$emit('input', formDefinition)"
            class="mt-0 mb-0 pt-0 pb-0"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SettingsForm",
  props: {
    value: Object,
  },
  data() {
    return {
      formDefinition: {},
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = { ...val };
      },
    },
  },
};
</script>

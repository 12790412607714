<template>
  <validation-observer ref="groupInputForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item>
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="name"
                @change="onSubmit()"
                ref="labelInput"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                v-model="createCondition"
                :items="createOptions"
                label="Create"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
              >
              </v-select>
            </validation-provider>

            <CreateComparisonConditionForm
              v-if="['conditional'].includes(createCondition)"
              v-model="selectedItemOptions.question.create"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex">
              <v-btn
                dark
                color="primary"
                tile
                width="calc(100% - 40px)"
                @click="addItem"
                class="text-truncate"
              >
                + {{ selectedQuestionType }}
              </v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    tile
                    dark
                    v-bind="attrs"
                    v-on="on"
                    width="30px"
                    min-width="30px"
                  >
                    <v-icon color="white">{{ mdiChevronDown }}</v-icon>
                  </v-btn>
                </template>
                <v-list style="max-height: 90vh" class="overflow-y-auto">
                  <v-list-item
                    v-for="t of repeatingGroupQuestionTypes"
                    :key="t.title"
                    @click="selectItemType(t.title)"
                  >
                    {{ t.title }}
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item
                    v-for="t of repeatingGroupInfoTypes"
                    :key="t.title"
                    @click="selectItemType(t.title)"
                  >
                    {{ t.title }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <RepeatingGroupItems
              v-model="selectedItemOptions.question.groupedItems"
              :formDefinition="formDefinition"
              :selectedItemOptions="selectedItemOptions"
              @remove-repeating-group-item="onRemoveRepeatingGroupItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <AdvancedSettingsForm
              v-model="selectedItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              :layer="layer"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasDefaultSettings"
                @click="onDefaultSettingClick"
              >
                <div>Default {{ defaultSetting }}</div>

                <v-btn icon>
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>

              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasVisibleSettings"
                @click="showAdvancedVisibilitySettingsDialog = true"
              >
                <div>Visible {{ visibleSetting }}</div>

                <v-btn icon>
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
            <v-btn color="primary" type="submit" width="100%">
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <AdvancedDefaultSettingsDialog
        v-if="showAdvancedDefaultSettingsDialog"
        :showAdvancedDefaultSettingsDialog="showAdvancedDefaultSettingsDialog"
        :formDefinition="formDefinition"
        :layer="layer"
        @advanced-settings-dialog-close="
          showAdvancedDefaultSettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />

      <AdvancedVisibilitySettingsDialog
        v-if="showAdvancedVisibilitySettingsDialog"
        :showAdvancedVisibilitySettingsDialog="
          showAdvancedVisibilitySettingsDialog
        "
        :formDefinition="formDefinition"
        :layer="layer"
        :selectedItem="selectedItem"
        @advanced-settings-dialog-close="
          showAdvancedVisibilitySettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />

      <AdvancedSettingsWarningDialog
        :showAdvancedSettingsWarningDialog="showAdvancedSettingsWarningDialog"
        @cancel="showAdvancedSettingsWarningDialog = false"
        @continue="openAdvancedSettingDialog"
      />
    </form>
  </validation-observer>
</template>

<script>
import { cloneDeep } from "lodash";
import { visibilityOptions, createOptions } from "@/constants/choices";
import { mdiChevronDown, mdiPencil } from "@mdi/js";
import {
  repeatingGroupQuestionTypes,
  getRepeatingGroupItemByType,
  repeatingGroupInfoTypes,
} from "@/constants/question";
import RepeatingGroupItems from "@/components/forms/form-builder-edit-form/form-control-edit-form/repeating-group-form/RepeatingGroupItems";
import sleep from "@/mixins/sleep";
import axios from "axios";
import CreateComparisonConditionForm from "./shared/CreateComparisonConditionForm";
import {
  CONDITION_CHOICES,
  ADVANCED_SETTINGS_CHOICES,
} from "@/constants/advancedSettings";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedDefaultSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedDefaultSettingsDialog.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import AdvancedSettingsWarningDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/add-advanced-settings-dialog/AdvancedSettingsWarningDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "RepeatingGroupForm",
  props: {
    selectedItem: Object,
    formDefinition: Object,
    edit: Boolean,
    selectedControlType: String,
  },
  components: {
    RepeatingGroupItems,
    CreateComparisonConditionForm,
    AdvancedSettingsForm,
    AdvancedVisibilitySettingsDialog,
    AdvancedDefaultSettingsDialog,
    AdvancedSettingsWarningDialog,
  },
  beforeMount() {
    this.getSelectedItemOptions();
    this.getLayer();
  },
  computed: {
    defaultSetting() {
      if (
        this.selectedItemOptions?.question?.default?.applyDefault === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.default?.applyDefault;
    },
    fieldChoices() {
      const fieldChoices = this.fields.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices;
    },
    userDataChoices() {
      const apiChoices = this.userDataFields.map(
        ({ user_data_field_id: value, name: label }) => ({
          value,
          label,
        })
      );
      return [
        { value: "f_name", label: "First Name" },
        { value: "l_name", label: "Last Name" },
        { value: "full_name", label: "Full Name" },
        { value: "email", label: "Email" },
        ...apiChoices,
      ];
    },
    gisDataChoices() {
      return this.gisDataFields.map(
        ({ gis_data_field_id: value, name, alias }) => ({
          value,
          label: alias || name,
        })
      );
    },
    defaultValueOptions() {
      return this.defaultValueTypes;
    },
    visibleSetting() {
      if (
        this.selectedItemOptions?.question?.visible?.applyVisible === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.visible?.applyVisible;
    },
    hasAdvancedSettings() {
      const { hasDefaultSettings, hasVisibleSettings } = this;
      return hasDefaultSettings || hasVisibleSettings;
    },
    hasDefaultSettings() {
      return Boolean(this.selectedItemOptions.question.default.type);
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.question.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
  },
  data() {
    return {
      selectedItemOptions: {
        question: {
          required: {
            condition: "ALWAYS",
          },
          visible: {
            condition: "ALWAYS",
          },
        },
      },
      oldSelectedItemOptions: {
        question: {
          default: {},
        },
      },
      createCondition: "ALWAYS",
      visibleCondition: "ALWAYS",
      visibilityOptions,
      mdiChevronDown,
      mdiPencil,
      selectedQuestionType: "Singleline Text",
      repeatingGroupQuestionTypes,
      fields: [],
      gisDataFields: [],
      userDataFields: [],
      defaultValueTypes: [
        {
          value: "USER",
          label: "User Data",
        },
        {
          value: "GIS",
          label: "GIS Field",
        },
        {
          value: "GIS_DATA",
          label: "UtiliSync Field",
        },
      ],
      layer: {},
      createOptions,
      repeatingGroupInfoTypes,
      showAdvancedVisibilitySettingsDialog: false,
      showAdvancedDefaultSettingsDialog: false,
      showAdvancedSettingsWarningDialog: false,
    };
  },
  methods: {
    onDefaultSettingClick() {
      this.advancedSettingType = ADVANCED_SETTINGS_CHOICES.DEFAULT;
      const hasOldSetting =
        !this.oldSelectedItemOptions?.question?.default?.applyDefault;
      if (hasOldSetting) {
        this.showAdvancedSettingsWarningDialog = true;
      } else {
        this.showAdvancedDefaultSettingsDialog = true;
      }
    },
    openAdvancedSettingDialog() {
      this.showAdvancedSettingsWarningDialog = false;
      if (this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.DEFAULT) {
        this.showAdvancedDefaultSettingsDialog = true;
      } else if (
        this.advancedSettingType === ADVANCED_SETTINGS_CHOICES.VISIBILITY
      ) {
        this.showAdvancedVisibilitySettingsDialog = true;
      }
    },
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    async getUserDataFields() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );

      const {
        data: { results },
      } = await axios.get(`${APIURL}/user_data_fields`, {
        params: {
          user_group_id: userGroupId,
        },
      });
      this.userDataFields = results;
    },
    async getGisDataFields() {
      if (this.edit) {
        if (this.formDefinition.map_service_id) {
          const {
            data: { results },
          } = await axios.get(`${APIURL}/gis_data_fields`, {
            params: { map_service_id: this.formDefinition.map_service_id },
          });
          this.gisDataFields = results;
        }
      } else {
        const { map_service_id: mapServiceId } = this.layer ?? {};
        const {
          data: { results },
        } = await axios.get(`${APIURL}/gis_data_fields`, {
          params: { map_service_id: mapServiceId },
        });
        this.gisDataFields = results;
      }
    },
    async getFeatureService() {
      try {
        const { map_service_id: mapServiceId } = this.formDefinition;
        const {
          data: {
            results: { service_url: featureServiceUrl, token_type: tokenType },
          },
        } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
        const token =
          tokenType === "AGOL" ? localStorage.getItem("esri_token") : undefined;
        const params = {
          f: "pjson",
          token,
        };
        const {
          data: { fields },
        } = await axios.get(featureServiceUrl, { params });
        this.fields = fields;
      } catch (error) {
        this.fields = [];
      }
    },
    async onSubmit() {
      const success = await this.$refs?.groupInputForm.validate();
      if (!success) {
        return;
      }
      const selectedItemOptions = cloneDeep(this.selectedItemOptions);
      this.$emit("input", selectedItemOptions);
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);
      this.oldSelectedItemOptions = cloneDeep(this.selectedItemOptions);

      if (
        this.selectedItemOptions?.question?.create &&
        !["ALWAYS"].includes(
          this.selectedItemOptions?.question?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedItemOptions.question.visible.condition;
      }

      if (!this.selectedItemOptions?.question?.default) {
        this.selectedItemOptions.question.default = {};
      }
    },
    selectItemType(type) {
      this.selectedQuestionType = type;
    },
    addItem() {
      const item = getRepeatingGroupItemByType(
        this.selectedQuestionType,
        this.selectedItemOptions.question.groupedItems
      );

      this.selectedItemOptions.question.groupedItems.push(item);
      this.onSubmit();
    },
    onRemoveRepeatingGroupItem(id) {
      const index = this.selectedItemOptions.question.groupedItems.findIndex(
        (it) => it.id === id
      );
      this.selectedItemOptions.question.groupedItems.splice(index, 1);
      this.onSubmit();
    },
  },
  async mounted() {
    if (!this.selectedItemOptions.question.label) {
      await this.$nextTick();
      await sleep(500);
      this.$refs?.labelInput?.$refs.input.focus();
    }
    this.getUserDataFields();
    this.getFeatureService();
    this.getGisDataFields();
  },
  watch: {
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.create.condition = val;
        this.selectedItemOptions.question.create.dependent = undefined;
        this.selectedItemOptions.question.create.dependentFieldId = undefined;
        this.selectedItemOptions.question.create.value = undefined;
      }
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  "hide-default-footer": "",
                  "disable-pagination": "",
                  items: _vm.newActivities,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            { staticClass: "ml-0 mr-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showCreateCityworksActivityDialog = true
                                        },
                                      },
                                    },
                                    [_vm._v(" + Cityworks Activity ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.newActivities.length > 0
                      ? {
                          key: "body",
                          fn: function ({ items }) {
                            return [
                              _c(
                                "tbody",
                                _vm._l(items, function (item, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: index,
                                      on: {
                                        click: function ($event) {
                                          _vm.itemToEdit = item
                                          _vm.itemIndex = index
                                          _vm.showCreateCityworksActivityDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(item.type))]),
                                      _c("td", [
                                        _vm._v(_vm._s(item.condition)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-right px-2" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "edit-button mr-1",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.itemToEdit = item
                                                  _vm.itemIndex = index
                                                  _vm.showCreateCityworksActivityDialog = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiPencil) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "delete-button ml-1",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.showRemoveDialog = true
                                                  _vm.itemIndex = index
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiDelete) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.showCreateCityworksActivityDialog,
            callback: function ($$v) {
              _vm.showCreateCityworksActivityDialog = $$v
            },
            expression: "showCreateCityworksActivityDialog",
          },
        },
        [
          _vm.showCreateCityworksActivityDialog
            ? _c("NewCityworksActivity", {
                attrs: { itemToEdit: _vm.itemToEdit, itemIndex: _vm.itemIndex },
                on: {
                  "create-cityworks-activity-close": function ($event) {
                    _vm.showCreateCityworksActivityDialog = false
                    _vm.itemToEdit = {}
                    _vm.itemIndex = undefined
                  },
                  input: function ($event) {
                    return _vm.$emit("input", _vm.formDefinition)
                  },
                },
                model: {
                  value: _vm.formDefinition,
                  callback: function ($$v) {
                    _vm.formDefinition = $$v
                  },
                  expression: "formDefinition",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
          },
          model: {
            value: _vm.showRemoveDialog,
            callback: function ($$v) {
              _vm.showRemoveDialog = $$v
            },
            expression: "showRemoveDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pt-5" },
            [
              _c("v-card-text", [
                _vm._v(
                  " Are you sure you want to delete this New Cityworks Activity? This cannot be undone. "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showRemoveDialog = false
                          _vm.itemIndex = undefined
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeItem(_vm.itemIndex)
                        },
                      },
                    },
                    [_vm._v(" Remove ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    { ref: "sectionForm" },
    [
      _c(
        "v-toolbar",
        {
          ref: "toolbar",
          staticClass: "elevation-0",
          attrs: { color: "primary", height: "56px" },
        },
        [
          _c(
            "v-list-item",
            { staticClass: "px-0", attrs: { "two-line": "" } },
            [
              _c("v-list-item-content", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "white--text text-h6" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("itemTitle")(
                                _vm.selectedControlType,
                                _vm.selectedItem
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("form-control-form-submitted")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.onSubmit()
              _vm.$emit("form-control-form-submitted")
            },
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "calc(100vh - 56px)" },
              attrs: { dense: "" },
            },
            [
              _c(
                "v-list-item",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Section Label", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "Section Label",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedItemOptions.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedItemOptions,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "selectedItemOptions.name",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { dense: "" } },
                [
                  _c("v-switch", {
                    attrs: { label: "Number Options" },
                    on: {
                      change: function ($event) {
                        return _vm.onSubmit()
                      },
                    },
                    model: {
                      value: _vm.selectedItemOptions.numbered,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectedItemOptions, "numbered", $$v)
                      },
                      expression: "selectedItemOptions.numbered",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { dense: "" } },
                [
                  _c("v-switch", {
                    staticClass: "my-0 py-0",
                    attrs: { label: "Section Open By Default" },
                    on: {
                      change: function ($event) {
                        return _vm.onSubmit()
                      },
                    },
                    model: {
                      value: _vm.selectedItemOptions.isOpenByDefault,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.selectedItemOptions,
                          "isOpenByDefault",
                          $$v
                        )
                      },
                      expression: "selectedItemOptions.isOpenByDefault",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { dense: "" } },
                [
                  _c("v-switch", {
                    staticClass: "my-0 py-0",
                    attrs: { label: "Hide Section Header" },
                    on: {
                      change: function ($event) {
                        return _vm.onSubmit()
                      },
                    },
                    model: {
                      value: _vm.selectedItemOptions.hideHeader,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectedItemOptions, "hideHeader", $$v)
                      },
                      expression: "selectedItemOptions.hideHeader",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Visible" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.visibilityOptions,
                                    label: "Visible",
                                    "error-messages": errors,
                                    success: valid,
                                    "item-text": "label",
                                    "item-value": "value",
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value: _vm.visibleCondition,
                                    callback: function ($$v) {
                                      _vm.visibleCondition = $$v
                                    },
                                    expression: "visibleCondition",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["conditional"].includes(_vm.visibleCondition)
                        ? _c("ComparisonConditionForm", {
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              selectedItem: _vm.selectedItem,
                              itemType: "section",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value: _vm.selectedItemOptions.visible,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions,
                                  "visible",
                                  $$v
                                )
                              },
                              expression: "selectedItemOptions.visible",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            width: "100%",
                          },
                        },
                        [_vm._v(" Update Item ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ma-0 pa-0 elevation-0 mx-auto" }, [
    _c("section", { staticClass: "elevation-0 pa-0" }, [
      _c(
        "div",
        { staticClass: "ma-0 pa-0" },
        [
          _c("EditTemplate", {
            attrs: {
              activeTab: _vm.activeTab,
              disabled: _vm.disabled,
              selectedFormDefLayer: _vm.selectedFormDefLayer,
              isLocateRequestLayer: _vm.isLocateRequestLayer,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", _vm.formDefinition)
              },
            },
            model: {
              value: _vm.formDefinition,
              callback: function ($$v) {
                _vm.formDefinition = $$v
              },
              expression: "formDefinition",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
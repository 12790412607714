<template>
  <validation-observer>
    <v-list dense>
      <v-list-item class="pr-0 mr-0 my-0 py-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Dependant"
            rules="required"
          >
            <v-autocomplete
              v-model="requiredVisibleOptions.dependantId"
              :items="dependantOptions"
              label="Dependant"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', requiredVisibleOptions)"
              hide-details
            ></v-autocomplete>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        class="pr-0 mr-0 my-0 py-0"
        v-if="groupItemDependantOptions.length > 0"
      >
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Dependant Group Item"
            :rules="{ required: groupItemDependantOptions.length > 0 }"
          >
            <v-autocomplete
              v-model="requiredVisibleOptions.dependantGroupItemId"
              :items="groupItemDependantOptions"
              label="Dependant Group Item"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', requiredVisibleOptions)"
              hide-details
              style="max-width: 168px"
            ></v-autocomplete>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pr-0 mr-0 my-0 py-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Comparison"
            rules="required"
          >
            <v-autocomplete
              v-model="requiredVisibleOptions.condition"
              :items="comparisonOptions"
              label="Comparison"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              @change="$emit('input', requiredVisibleOptions)"
              hide-details
            ></v-autocomplete>
          </validation-provider>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="pr-0 mr-0">
        <v-list-item-content>
          <validation-provider
            v-slot="{ errors, valid }"
            name="Value"
            rules="required"
          >
            <template v-if="isSelectQuestion">
              <template v-if="isImageSelectQuestion">
                <v-radio-group
                  v-model="requiredVisibleOptions.value"
                  @change="$emit('input', requiredVisibleOptions)"
                >
                  <v-radio
                    v-for="option of comparisonValues"
                    :key="option.value"
                    :value="option.value"
                  >
                    <template v-slot:label>
                      <img :src="option.value" />
                    </template>
                  </v-radio>
                </v-radio-group>
              </template>

              <v-autocomplete
                v-else
                v-model="requiredVisibleOptions.value"
                :items="comparisonValues"
                label="Value"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="$emit('input', requiredVisibleOptions)"
                hide-details
              >
              </v-autocomplete>
            </template>
            <v-text-field
              v-else
              v-model="requiredVisibleOptions.value"
              label="Value"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              @change="$emit('input', requiredVisibleOptions)"
            />
          </validation-provider>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </validation-observer>
</template>

<script>
import { cloneDeep } from "lodash";
import { comparisonTypes } from "@/constants/choices";

export default {
  name: "ComparisonConditionForm",
  props: {
    value: Object,
    formDefinition: Object,
    selectedItem: Object,
    itemType: {
      type: String,
      validator: (val) => ["section", "item"].includes(val),
      default: "item",
    },
    isRepeatingGroupItem: Boolean,
  },
  computed: {
    comparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) => id === this.requiredVisibleOptions.dependantId
      );
      if (dependantItem?.question?.type === "NUMBER") {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    groupItemDependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      const flattenedSectionItems = sectionItems.flat();
      const selectedGroup = flattenedSectionItems.find(({ id }) => {
        return +id === +this.requiredVisibleOptions.dependantId;
      });
      if (!selectedGroup) {
        return [];
      }
      const {
        question: { groupedItems, label: groupLabel },
      } = selectedGroup;
      const groupItemChoices =
        groupedItems
          ?.filter(
            ({ id, type: itemType, question: { type, isLong } = {} }) => {
              return (
                id !== this.selectedItem.id &&
                itemType !== "INFORMATION" &&
                ![
                  "MULTI_SELECT",
                  "SIGNATURE",
                  "PHOTO",
                  "FILE",
                  "GROUP",
                ].includes(type) &&
                !isLong
              );
            }
          )
          ?.map(({ id, question: { label } = {} }) => ({
            value: id,
            label: `${groupLabel} - ${label}`,
          })) ?? [];

      return groupItemChoices;
    },
    dependantOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      const flattenedSectionItems = sectionItems.flat();
      if (this.itemType === "section") {
        return flattenedSectionItems
          .filter(({ type: itemType, question: { type } = {} }) => {
            return (
              itemType !== "INFORMATION" &&
              !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE"].includes(type)
            );
          })
          .map(({ id, number, question: { label } = {} }) => ({
            value: id,
            label: `${number} ${label}`,
          }));
      }
      if (this.isRepeatingGroupItem) {
        return flattenedSectionItems
          .filter(({ type: itemType, question: { type, isLong } = {} }) => {
            if (type === "GROUP") {
              return true;
            }
            return (
              itemType !== "INFORMATION" &&
              !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE"].includes(type) &&
              !isLong
            );
          })
          .map(({ id, number, question: { label } = {} }) => ({
            value: id,
            label: `${number} ${label}`,
          }));
      }
      return flattenedSectionItems
        .filter(({ id, type: itemType, question: { type, isLong } = {} }) => {
          if (type === "GROUP") {
            return true;
          }
          return (
            id !== this.selectedItem.id &&
            itemType !== "INFORMATION" &&
            !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE"].includes(type) &&
            !isLong
          );
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
    isSelectQuestion() {
      const { selectedDependantQuestion } = this;
      return ["SINGLE_SELECT"].includes(
        selectedDependantQuestion?.question?.type
      );
    },
    isImageSelectQuestion() {
      const { selectedDependantQuestion } = this;
      return (
        ["SINGLE_SELECT"].includes(selectedDependantQuestion?.question?.type) &&
        selectedDependantQuestion?.question?.isImage
      );
    },
    selectedDependantQuestion() {
      const { dependantId, dependantGroupItemId } =
        this.requiredVisibleOptions ?? {};
      const formDefinition = cloneDeep(this.formDefinition);
      const sectionItems =
        formDefinition?.form?.sections.map((s) => s.items).flat() ?? [];
      if (dependantId && dependantGroupItemId) {
        const item = sectionItems.find(({ id }) => id === dependantId);
        const groupedItems = item?.question?.groupedItems ?? [];
        return groupedItems?.find(({ id }) => id === dependantGroupItemId);
      }
      return sectionItems.find(({ id }) => id === dependantId);
    },
    comparisonValues() {
      const { selectedDependantQuestion } = this;
      return selectedDependantQuestion?.question?.options;
    },
  },
  data() {
    return {
      requiredVisibleOptions: {},
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.requiredVisibleOptions = cloneDeep(val) ?? {};
      },
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _vm.item.question.isImage
        ? [
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [
                _c("p", { staticClass: "caption" }, [
                  _vm._v(" " + _vm._s(_vm.item.question.label) + " "),
                ]),
                _c(
                  "v-radio-group",
                  {
                    attrs: { disabled: _vm.readOnly },
                    model: {
                      value: _vm.selectedValue,
                      callback: function ($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue",
                    },
                  },
                  _vm._l(_vm.options, function (option) {
                    return _c("v-radio", {
                      key: option.id,
                      attrs: { value: option.value, readonly: _vm.readOnly },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("img", { attrs: { src: option.value } }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _vm.item.question.showAllOptions
              ? [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(" " + _vm._s(_vm.item.question.label) + " "),
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { disabled: _vm.readOnly },
                          on: { change: _vm.onInputChange },
                          model: {
                            value: _vm.selectedValue,
                            callback: function ($$v) {
                              _vm.selectedValue = $$v
                            },
                            expression: "selectedValue",
                          },
                        },
                        _vm._l(_vm.options, function (option) {
                          return _c("v-radio", {
                            key: option.id,
                            attrs: { value: option.value, label: option.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          slim: "",
                          name: _vm.item.question.label,
                          rules: {
                            required: _vm.isRequired,
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.options,
                                    "item-text": "label",
                                    "item-value": "value",
                                    label: _vm.item.question.label,
                                    "error-messages": errors,
                                    success: valid,
                                    disabled: _vm.readOnly,
                                  },
                                  on: { change: _vm.onInputChange },
                                  model: {
                                    value: _vm.selectedValue,
                                    callback: function ($$v) {
                                      _vm.selectedValue = $$v
                                    },
                                    expression: "selectedValue",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
          ],
      _vm.showTextInput
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("validation-provider", {
                attrs: { name: _vm.item.question.label, rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "auto",
                              "error-messages": errors,
                              success: valid,
                              color: "primary",
                              name: _vm.item.id,
                              disabled: _vm.readOnly,
                              label: _vm.item.question.label,
                            },
                            on: { input: _vm.onInputChange },
                            model: {
                              value: _vm.otherValue,
                              callback: function ($$v) {
                                _vm.otherValue = $$v
                              },
                              expression: "otherValue",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3207240748
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    { ref: "groupInputForm" },
    [
      _c(
        "v-toolbar",
        {
          ref: "toolbar",
          staticClass: "elevation-0",
          attrs: { color: "primary", height: "56px" },
        },
        [
          _c(
            "v-list-item",
            { staticClass: "px-0", attrs: { "two-line": "" } },
            [
              _c("v-list-item-content", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "white--text text-h6" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("itemTitle")(
                                _vm.selectedControlType,
                                _vm.selectedItem
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("form-control-form-submitted")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.onSubmit()
              _vm.$emit("form-control-form-submitted")
            },
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "calc(100vh - 56px)" },
              attrs: { dense: "" },
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Label", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  ref: "labelInput",
                                  attrs: {
                                    label: "Label",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "name",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedItemOptions.question.label,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedItemOptions.question,
                                        "label",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedItemOptions.question.label",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Visible" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.createOptions,
                                    label: "Create",
                                    "error-messages": errors,
                                    success: valid,
                                    "item-text": "label",
                                    "item-value": "value",
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value: _vm.createCondition,
                                    callback: function ($$v) {
                                      _vm.createCondition = $$v
                                    },
                                    expression: "createCondition",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["conditional"].includes(_vm.createCondition)
                        ? _c("CreateComparisonConditionForm", {
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              selectedItem: _vm.selectedItem,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value: _vm.selectedItemOptions.question.create,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions.question,
                                  "create",
                                  $$v
                                )
                              },
                              expression: "selectedItemOptions.question.create",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-truncate",
                            attrs: {
                              dark: "",
                              color: "primary",
                              tile: "",
                              width: "calc(100% - 40px)",
                            },
                            on: { click: _vm.addItem },
                          },
                          [
                            _vm._v(
                              " + " + _vm._s(_vm.selectedQuestionType) + " "
                            ),
                          ]
                        ),
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "primary",
                                              tile: "",
                                              dark: "",
                                              width: "30px",
                                              "min-width": "30px",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "white" } },
                                          [_vm._v(_vm._s(_vm.mdiChevronDown))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              {
                                staticClass: "overflow-y-auto",
                                staticStyle: { "max-height": "90vh" },
                              },
                              [
                                _vm._l(
                                  _vm.repeatingGroupQuestionTypes,
                                  function (t) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: t.title,
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectItemType(t.title)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(t.title) + " ")]
                                    )
                                  }
                                ),
                                _c("v-divider"),
                                _vm._l(
                                  _vm.repeatingGroupInfoTypes,
                                  function (t) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: t.title,
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectItemType(t.title)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(t.title) + " ")]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("RepeatingGroupItems", {
                        attrs: {
                          formDefinition: _vm.formDefinition,
                          selectedItemOptions: _vm.selectedItemOptions,
                        },
                        on: {
                          "remove-repeating-group-item":
                            _vm.onRemoveRepeatingGroupItem,
                          input: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value: _vm.selectedItemOptions.question.groupedItems,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedItemOptions.question,
                              "groupedItems",
                              $$v
                            )
                          },
                          expression:
                            "selectedItemOptions.question.groupedItems",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("AdvancedSettingsForm", {
                        attrs: {
                          formDefinition: _vm.formDefinition,
                          selectedItem: _vm.selectedItem,
                          layer: _vm.layer,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value: _vm.selectedItemOptions,
                          callback: function ($$v) {
                            _vm.selectedItemOptions = $$v
                          },
                          expression: "selectedItemOptions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm.hasAdvancedSettings
                      ? _c("div", { staticClass: "d-flex flex-wrap gap" }, [
                          _vm.hasDefaultSettings
                            ? _c(
                                "section",
                                {
                                  staticClass:
                                    "text-uppercase d-flex primary--text gap align-center",
                                  on: { click: _vm.onDefaultSettingClick },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Default " + _vm._s(_vm.defaultSetting)
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    { attrs: { icon: "" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiPencil) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasVisibleSettings
                            ? _c(
                                "section",
                                {
                                  staticClass:
                                    "text-uppercase d-flex primary--text gap align-center",
                                  on: {
                                    click: function ($event) {
                                      _vm.showAdvancedVisibilitySettingsDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Visible " + _vm._s(_vm.visibleSetting)
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    { attrs: { icon: "" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiPencil) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _c(
                          "div",
                          { staticClass: "d-flex align-center justify-center" },
                          [_vm._v(" No advanced settings ")]
                        ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            width: "100%",
                          },
                        },
                        [_vm._v(" Update Item ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showAdvancedDefaultSettingsDialog
            ? _c("AdvancedDefaultSettingsDialog", {
                attrs: {
                  showAdvancedDefaultSettingsDialog:
                    _vm.showAdvancedDefaultSettingsDialog,
                  formDefinition: _vm.formDefinition,
                  layer: _vm.layer,
                },
                on: {
                  "advanced-settings-dialog-close": function ($event) {
                    _vm.showAdvancedDefaultSettingsDialog = false
                  },
                  input: function ($event) {
                    return _vm.onSubmit()
                  },
                },
                model: {
                  value: _vm.selectedItemOptions,
                  callback: function ($$v) {
                    _vm.selectedItemOptions = $$v
                  },
                  expression: "selectedItemOptions",
                },
              })
            : _vm._e(),
          _vm.showAdvancedVisibilitySettingsDialog
            ? _c("AdvancedVisibilitySettingsDialog", {
                attrs: {
                  showAdvancedVisibilitySettingsDialog:
                    _vm.showAdvancedVisibilitySettingsDialog,
                  formDefinition: _vm.formDefinition,
                  layer: _vm.layer,
                  selectedItem: _vm.selectedItem,
                },
                on: {
                  "advanced-settings-dialog-close": function ($event) {
                    _vm.showAdvancedVisibilitySettingsDialog = false
                  },
                  input: function ($event) {
                    return _vm.onSubmit()
                  },
                },
                model: {
                  value: _vm.selectedItemOptions,
                  callback: function ($$v) {
                    _vm.selectedItemOptions = $$v
                  },
                  expression: "selectedItemOptions",
                },
              })
            : _vm._e(),
          _c("AdvancedSettingsWarningDialog", {
            attrs: {
              showAdvancedSettingsWarningDialog:
                _vm.showAdvancedSettingsWarningDialog,
            },
            on: {
              cancel: function ($event) {
                _vm.showAdvancedSettingsWarningDialog = false
              },
              continue: _vm.openAdvancedSettingDialog,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
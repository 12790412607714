import Paragraph from "@tiptap/extension-paragraph";

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      display: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.display) {
            return {};
          }

          return {
            style: `display: ${attributes.display}`,
          };
        },
        parseHTML: (element) => {
          return element.style.display.replace(/['"]+/g, "");
        },
      },
      width: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.width) {
            return {};
          }

          return {
            style: `width: ${attributes.width}`,
          };
        },
        parseHTML: (element) => {
          return element.style.width.replace(/['"]+/g, "");
        },
      },
      padding: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.padding) {
            return {};
          }

          return {
            style: `padding: ${attributes.padding}`,
          };
        },
        parseHTML: (element) => {
          return element.style.padding.replace(/['"]+/g, "");
        },
      },
      marginTop: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginTop) {
            return {};
          }

          return {
            style: `margin-top: ${attributes.marginTop}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginTop.replace(/['"]+/g, "");
        },
      },
      marginBottom: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginBottom) {
            return {};
          }

          return {
            style: `margin-bottom: ${attributes.marginBottom}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginBottom.replace(/['"]+/g, "");
        },
      },
      marginLeft: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginLeft) {
            return {};
          }

          return {
            style: `margin-left: ${attributes.marginLeft}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginLeft.replace(/['"]+/g, "");
        },
      },
      marginRight: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.marginRight) {
            return {};
          }

          return {
            style: `margin-top: ${attributes.marginRight}`,
          };
        },
        parseHTML: (element) => {
          return element.style.marginRight.replace(/['"]+/g, "");
        },
      },
      fontSize: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.fontSize) {
            return {};
          }

          return {
            style: `font-size: ${attributes.fontSize}`,
          };
        },
        parseHTML: (element) => {
          return element.style.fontSize.replace(/['"]+/g, "");
        },
      },
      class: {
        default: null,
      },
    };
  },
});

export default CustomParagraph;

<template>
  <section>
    <div class="d-flex align-center justify-space-between">
      <div>Advanced Settings</div>

      <v-btn text color="primary" @click="showAddAdvancedSettingsDialog = true">
        + Add
      </v-btn>
    </div>

    <AddAdvancedSettingsDialog
      v-if="showAddAdvancedSettingsDialog"
      :showAddAdvancedSettingsDialog="showAddAdvancedSettingsDialog"
      :selectedItemOptions="selectedItemOptions"
      :oldSelectedItemOptions="oldSelectedItemOptions"
      @advanced-settings-selected="onAdvancedSettingSelected"
      @add-advanced-settings-dialog-close="
        showAddAdvancedSettingsDialog = false
      "
    />

    <AdvancedDefaultSettingsDialog
      v-if="showAdvancedDefaultSettingsDialog"
      :showAdvancedDefaultSettingsDialog="showAdvancedDefaultSettingsDialog"
      :formDefinition="formDefinition"
      :layer="layer"
      @advanced-settings-dialog-close="
        showAdvancedDefaultSettingsDialog = false
      "
      v-model="selectedItemOptions"
      @input="onInput"
    />

    <AdvancedVisibilitySettingsDialog
      v-if="showAdvancedVisibilitySettingsDialog"
      :showAdvancedVisibilitySettingsDialog="
        showAdvancedVisibilitySettingsDialog
      "
      :formDefinition="formDefinition"
      :layer="layer"
      :selectedItem="selectedItem"
      @advanced-settings-dialog-close="
        showAdvancedVisibilitySettingsDialog = false
      "
      v-model="selectedItemOptions"
      @input="onInput"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import AdvancedDefaultSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedDefaultSettingsDialog.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import AddAdvancedSettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AddAdvancedSettingsDialog.vue";
import { ADVANCED_SETTINGS_CHOICES } from "@/constants/advancedSettings";

export default {
  name: "AdvancedSettingsForm",
  props: {
    value: Object,
    formDefinition: Object,
    layer: Object,
    selectedItem: Object,
    oldSelectedItemOptions: Object,
  },
  components: {
    AdvancedDefaultSettingsDialog,
    AdvancedVisibilitySettingsDialog,
    AddAdvancedSettingsDialog,
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.selectedItemOptions = cloneDeep(val);
      },
    },
  },
  data() {
    return {
      selectedItemOptions: {},
      showAdvancedDefaultSettingsDialog: false,
      showAdvancedVisibilitySettingsDialog: false,
      showAddAdvancedSettingsDialog: false,
      advancedSettingType: ADVANCED_SETTINGS_CHOICES.DEFAULT,
    };
  },
  beforeMount() {
    this.selectedItemOptions = cloneDeep(this.value);
  },
  methods: {
    onAdvancedSettingSelected(advancedSettingType) {
      this.showAddAdvancedSettingsDialog = false;
      if (advancedSettingType === ADVANCED_SETTINGS_CHOICES.DEFAULT) {
        this.showAdvancedDefaultSettingsDialog = true;
      } else if (advancedSettingType === ADVANCED_SETTINGS_CHOICES.VISIBILITY) {
        this.showAdvancedVisibilitySettingsDialog = true;
      }
    },
    onInput(selectedItemOptions) {
      this.$emit("input", selectedItemOptions);
    },
  },
};
</script>

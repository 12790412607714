var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "validation-observer",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "To",
                          rules: "required|email_or_params",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-combobox", {
                                  attrs: {
                                    label: "To *",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "to",
                                    hint: `Email or {{params.x}}`,
                                    "persistent-hint": "",
                                    chips: "",
                                    multiple: "",
                                    "item-text": "label",
                                    "item-value": "value",
                                    "append-icon": "",
                                  },
                                  on: { input: _vm.onInput },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  close: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.onClickClose(
                                                      _vm.index,
                                                      "toEmails",
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getChipDisplayName(
                                                        item
                                                      )
                                                    )
                                                  ),
                                                ],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.formDefinition.form.emailSettings[
                                        _vm.index
                                      ].toEmails,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formDefinition.form.emailSettings[
                                          _vm.index
                                        ],
                                        "toEmails",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formDefinition.form.emailSettings[index].toEmails",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "CC", rules: "email_or_params" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-combobox", {
                                  attrs: {
                                    label: "CC",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "cc",
                                    hint: `Email or {{params.x}}`,
                                    "persistent-hint": "",
                                    chips: "",
                                    multiple: "",
                                    "item-text": "label",
                                    "item-value": "value",
                                    "append-icon": "",
                                  },
                                  on: { input: _vm.onInput },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function ({ selected, item }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "input-value": selected,
                                                  close: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.onClickClose(
                                                      _vm.index,
                                                      "cc",
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                item.value
                                                  ? [_vm._v(_vm._s(item.value))]
                                                  : [_vm._v(_vm._s(item))],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.formDefinition.form.emailSettings[
                                        _vm.index
                                      ].cc,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formDefinition.form.emailSettings[
                                          _vm.index
                                        ],
                                        "cc",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formDefinition.form.emailSettings[index].cc",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "BCC", rules: "email_or_params" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-combobox", {
                                  attrs: {
                                    label: "BCC",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "bcc",
                                    hint: `Email or {{params.x}}`,
                                    "persistent-hint": "",
                                    chips: "",
                                    multiple: "",
                                    "item-text": "label",
                                    "item-value": "value",
                                    "append-icon": "",
                                  },
                                  on: { input: _vm.onInput },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function ({ selected, item }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "input-value": selected,
                                                  close: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.onClickClose(
                                                      _vm.index,
                                                      "bcc",
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                item.value
                                                  ? [_vm._v(_vm._s(item.value))]
                                                  : [_vm._v(_vm._s(item))],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.formDefinition.form.emailSettings[
                                        _vm.index
                                      ].bcc,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formDefinition.form.emailSettings[
                                          _vm.index
                                        ],
                                        "bcc",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formDefinition.form.emailSettings[index].bcc",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "From Name", rules: "required" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "From Name",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                    hint: "The name, not email, of who it is from",
                                    "persistent-hint": "",
                                    height: "42px",
                                  },
                                  on: { input: _vm.onInput },
                                  model: {
                                    value:
                                      _vm.formDefinition.form.emailSettings[
                                        _vm.index
                                      ].fromName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formDefinition.form.emailSettings[
                                          _vm.index
                                        ],
                                        "fromName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formDefinition.form.emailSettings[index].fromName",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "Reply To",
                          rules: "single_email_or_params",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-combobox", {
                                  attrs: {
                                    label: "Reply to Email",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "replyTo",
                                    hint: `Email or {{params.x}}`,
                                    "persistent-hint": "",
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: { input: _vm.onInput },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "selection",
                                        fn: function ({ selected, item }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  "input-value": selected,
                                                  close: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    _vm.formDefinition.form.emailSettings[
                                                      _vm.index
                                                    ].replyTo = undefined
                                                  },
                                                },
                                              },
                                              [
                                                item.value
                                                  ? [_vm._v(_vm._s(item.value))]
                                                  : [_vm._v(_vm._s(item))],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.formDefinition.form.emailSettings[
                                        _vm.index
                                      ].replyTo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formDefinition.form.emailSettings[
                                          _vm.index
                                        ],
                                        "replyTo",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formDefinition.form.emailSettings[index].replyTo",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
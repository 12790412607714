<template>
  <v-edit-dialog
    save-text="Save"
    cancel-text="Cancel"
    large
    persistent
    @cancel="onCancel"
    @close="onCancel"
    @save="onSave"
  >
    <slot></slot>

    <template v-slot:input>
      <div>
        <validation-observer ref="visibleConditionForm">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Visible"
            rules="required"
          >
            <v-select
              v-model="visibleCondition"
              :items="visibilityOptions"
              label="Visible"
              :error-messages="errors"
              :success="valid"
              item-text="label"
              item-value="value"
              hide-details
            >
            </v-select>
          </validation-provider>

          <template v-if="visibleCondition === 'conditional'">
            <validation-provider v-slot="{ errors, valid }" name="Type">
              <v-select
                v-model="visibleConditionOptions.type"
                :items="dependentValueOptions"
                label="Type"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Field"
              rules="required"
              v-if="
                [
                  DEPENDANT_OPTIONS.LOCATE_DATA,
                  DEPENDANT_OPTIONS.FORM_ITEM,
                ].includes(visibleConditionOptions.type)
              "
            >
              <v-select
                v-model="visibleConditionOptions.field"
                :items="getOptionValues(visibleConditionOptions.type)"
                label="Field"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Comparison"
              rules="required"
            >
              <v-select
                label="Comparison"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="dependantId"
                :items="visibleComparisonOptions"
                item-text="label"
                item-value="value"
                v-model="visibleConditionOptions.condition"
              >
              </v-select>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Value"
              rules="required"
            >
              <v-text-field
                v-model="visibleConditionOptions.value"
                label="Value"
                :error-messages="errors"
                :success="valid"
                hide-details
              >
              </v-text-field>
            </validation-provider>
          </template>
        </validation-observer>
      </div>
    </template>
  </v-edit-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import {
  CONDITION_CHOICES,
  DEPENDANT_OPTIONS,
} from "@/constants/advancedSettings";
import { visibilityOptions, comparisonTypes } from "@/constants/choices";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "VisibleConditionDialog",
  props: {
    value: Object,
    formDefinition: Object,
  },
  computed: {
    visibleComparisonOptions() {
      const formDefinition = { ...this.formDefinition };
      const items = formDefinition.form.sections.map((s) => s.items).flat();
      const dependantItem = items.find(
        ({ id }) => id === this.visibleConditionOptions.field
      );
      if (dependantItem?.question?.type === "NUMBER") {
        return comparisonTypes;
      }
      return [
        {
          value: "NOT_EQUAL",
          label: "Not Equal",
        },
        {
          value: "EQUAL",
          label: "Equal",
        },
      ];
    },
    fieldChoices() {
      const fieldChoices = this.fields?.map(({ name, alias }) => ({
        value: name,
        label: alias || name,
      }));
      return fieldChoices ?? [];
    },
    dependentValueOptions() {
      return this.defaultValueTypes;
    },
    formFieldOptions() {
      const formDefinition = { ...this.formDefinition };
      const sectionItems = formDefinition.form.sections.map((s) => s.items);
      const flattenedSectionItems = sectionItems.flat();
      return flattenedSectionItems
        .filter(({ type: itemType, question: { type } = {} }) => {
          return (
            itemType !== "INFORMATION" &&
            !["MULTI_SELECT", "SIGNATURE", "PHOTO", "FILE", "GROUP"].includes(
              type
            )
          );
        })
        .map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        }));
    },
  },
  data() {
    return {
      defaultValueTypes: [
        {
          value: DEPENDANT_OPTIONS.LOCATE_DATA,
          label: "811 Ticket",
        },
        {
          value: DEPENDANT_OPTIONS.FORM_ITEM,
          label: "Form Field",
        },
      ],
      visibleConditionOptions: {
        condition: MATCH_CHOICE_VALUES.EQUAL,
      },
      layer: {},
      visibilityOptions,
      visibleCondition: "ALWAYS",
      DEPENDANT_OPTIONS,
      locateRequestDataValues: [
        {
          value: "ticket_type",
          label: "Ticket Type",
        },
      ],
      comparisonTypes,
    };
  },
  methods: {
    getOptionValues(visibleConditionType) {
      if (visibleConditionType === DEPENDANT_OPTIONS.LOCATE_DATA) {
        return this.locateRequestDataValues;
      } else if (visibleConditionType === DEPENDANT_OPTIONS.FORM_ITEM) {
        return this.formFieldOptions;
      }
    },
    onCancel() {
      this.$emit("input", cloneDeep(this.value));
    },
    async onSave() {
      const success = await this.$refs?.visibleConditionForm?.validate();
      if (!success) {
        return;
      }
      const newValue = cloneDeep({
        ...this.visibleConditionOptions,
        condition:
          this.visibleCondition === "ALWAYS"
            ? "ALWAYS"
            : this.visibleConditionOptions.condition,
      });
      this.$emit("input", newValue);
    },

    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
  },
  mounted() {
    this.getLayer();
  },
  watch: {
    "visibleConditionOptions.dependentFieldId"(val) {
      if (val) {
        this.visibleConditionOptions.condition = "EQUAL";
      }
    },
    visibleCondition(val) {
      if (val === CONDITION_CHOICES.ALWAYS) {
        this.visibleConditionOptions = {
          condition: CONDITION_CHOICES.ALWAYS,
        };
      } else if (val === CONDITION_CHOICES.NEVER) {
        this.visibleConditionOptions = {
          condition: CONDITION_CHOICES.NEVER,
        };
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.visibleConditionOptions = cloneDeep(val);
        if (
          !["ALWAYS", "NEVER"].includes(this.visibleConditionOptions?.condition)
        ) {
          this.visibleCondition = "conditional";
        } else {
          this.visibleCondition = this.visibleConditionOptions?.condition;
        }
      },
    },
  },
};
</script>

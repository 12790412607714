var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between" },
    [
      _c("validation-observer", { ref: "photoForm" }, [
        _c(
          "form",
          [
            _vm.selectedRepeatingGroupItemOptions &&
            _vm.selectedRepeatingGroupItemOptions.question
              ? _c(
                  "v-list",
                  [
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-menu",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-space-between",
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "font-weight-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("itemTitle")(
                                                          _vm
                                                            .selectedRepeatingGroupItemOptions
                                                            .question.type,
                                                          _vm.selectedRepeatingGroupItemOptions
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { icon: "" } },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.mdiDotsVertical
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  583353061
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeRepeatingGroupItem(
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(_vm.mdiDelete)),
                                            ]),
                                            _vm._v(" Delete "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("validation-provider", {
                              attrs: { name: "Label", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Label",
                                            "hide-details": "auto",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            name: "name",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.label,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedRepeatingGroupItemOptions.question.label",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3137863299
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("validation-provider", {
                              attrs: { name: "Required", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.groupItemRequiredOptions,
                                            label: "Required",
                                            "error-messages": errors,
                                            success: valid,
                                            "item-text": "label",
                                            "item-value": "value",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onSubmit()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .selectedRepeatingGroupItemOptions
                                                .question.required.condition,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm
                                                  .selectedRepeatingGroupItemOptions
                                                  .question.required,
                                                "condition",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                  selectedRepeatingGroupItemOptions.question.required\n                    .condition\n                ",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4045653705
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.selectedRepeatingGroupItemOptions.question.isImage
                      ? _c(
                          "v-list-item",
                          { staticClass: "pa-0 ma-0" },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Maximum Width/Height of Photo",
                                    rules: "min_value:0",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-text-field", {
                                              attrs: {
                                                label:
                                                  "Maximum Width/Height of Photo",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "name",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSubmit()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .selectedRepeatingGroupItemOptions
                                                    .question.maxWidthHeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm
                                                      .selectedRepeatingGroupItemOptions
                                                      .question,
                                                    "maxWidthHeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                  selectedRepeatingGroupItemOptions.question.maxWidthHeight\n                ",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2699135029
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                          [
                            _c("v-switch", {
                              attrs: { label: "Show Description" },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions.question
                                    .showDescription,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions
                                      .question,
                                    "showDescription",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n                selectedRepeatingGroupItemOptions.question.showDescription\n              ",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-list-item-content",
                          { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                          [
                            _c("v-switch", {
                              attrs: { label: "Allow Multiple" },
                              on: {
                                change: function ($event) {
                                  return _vm.onSubmit()
                                },
                              },
                              model: {
                                value:
                                  _vm.selectedRepeatingGroupItemOptions
                                    .allowMultiple,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectedRepeatingGroupItemOptions,
                                    "allowMultiple",
                                    $$v
                                  )
                                },
                                expression:
                                  "selectedRepeatingGroupItemOptions.allowMultiple",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
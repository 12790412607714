<template>
  <v-dialog
    :value="showRepeatingGroupDefaultValuesDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
  >
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        <div><b>Table Default Values</b></div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
        class="mt-6"
        label="Allow User to Add and Delete Rows"
        v-model="addOrDeleteRows"
      ></v-switch>
      <v-btn
        icon
        dark
        @click="$emit('repeating-group-default-values-dialog-close')"
      >
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>

    <v-card class="rounded-0">
      <v-card-text class="py-3">
        <v-responsive class="pa-0 ma-0">
          <validation-observer slim ref="repeatingGroupDefaultValuesForm">
            <v-data-table
              disable-pagination
              hide-default-header
              hide-default-footer
              class="d-block overflow-x-auto"
              v-if="repeatingGroupTableHeadings.length > 0"
              height="40vh"
            >
              <template v-slot:header>
                <thead>
                  <tr>
                    <th
                      class="px-1"
                      v-for="h of repeatingGroupTableHeadings"
                      :key="h.id"
                      v-show="h.isEditable"
                    >
                      <div v-if="h.isInfo && h.type === 'TEXT'">Text Info</div>
                      <div v-else>
                        {{ h.label }}
                      </div>
                    </th>
                    <th>Create</th>
                    <th></th>
                  </tr>
                </thead>
              </template>

              <template v-slot:body>
                <tbody>
                  <tr
                    v-for="(row, index) of repeatingGroupDefaultValues"
                    :key="index"
                  >
                    <td
                      class="px-1"
                      v-for="(item, itemIndex) of row"
                      :key="item.id"
                      @click.stop="
                        isEditing = {};
                        $set(isEditing, index, !isEditing[index]);
                      "
                      v-show="isEditable(item)"
                    >
                      <div v-if="!isEditing[index]">
                        <template v-if="isEditable(item)">
                          <v-btn
                            v-if="['IMAGE'].includes(getInformationType(item))"
                            color="primary"
                            text
                            class="px-0 mx-0"
                            min-width="0"
                          >
                            <v-icon class="mr-1">
                              {{ mdiImagePlus }}
                            </v-icon>
                            {{ getInfoImageFileName(item) }}
                          </v-btn>
                          <template
                            v-else-if="
                              ['MULTI_SELECT'].includes(getQuestionType(item))
                            "
                          >
                            {{
                              Array.isArray(item.value)
                                ? item.value.join(", ")
                                : item.value
                            }}
                          </template>
                          <template v-else>
                            {{ item.value }}
                          </template>
                        </template>
                        <template v-else> Not Editable </template>
                      </div>
                      <div v-else>
                        <validation-provider
                          v-if="
                            ['SINGLE_SELECT', 'MULTI_SELECT'].includes(
                              getQuestionType(item)
                            )
                          "
                          slim
                          v-slot="{ errors, valid }"
                          :name="item.question.label"
                        >
                          <v-select
                            color="primary"
                            v-model="item.value"
                            :error-messages="errors"
                            :success="valid"
                            @click.stop
                            :items="getQuestionOptions(item)"
                            item-text="label"
                            item-value="value"
                            :multiple="
                              ['MULTI_SELECT'].includes(getQuestionType(item))
                            "
                          ></v-select>
                        </validation-provider>
                        <validation-provider
                          v-else-if="
                            ['TEXT', 'EMAIL'].includes(getQuestionType(item))
                          "
                          slim
                          v-slot="{ errors, valid }"
                          :name="item.question.label"
                          :rules="{ email: item.question.type === 'EMAIL' }"
                        >
                          <v-text-field
                            color="primary"
                            v-model="item.value"
                            :error-messages="errors"
                            :success="valid"
                            @click.stop
                            :ref="itemIndex === 0 ? 'input' : undefined"
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider
                          v-else-if="['NUMBER'].includes(getQuestionType(item))"
                          slim
                          v-slot="{ errors, valid }"
                          :name="item.question.label"
                        >
                          <v-text-field
                            color="primary"
                            v-model="item.value"
                            :error-messages="errors"
                            :success="valid"
                            @click.stop
                            @keydown="onNumberKeyDown"
                            type="number"
                            :ref="itemIndex === 0 ? 'input' : undefined"
                          ></v-text-field>
                        </validation-provider>
                        <RepeatinghGroupDefaultValueDateInput
                          v-else-if="['DATE'].includes(getQuestionType(item))"
                          :item="item"
                          v-model="item.value"
                        />
                        <RepeatinghGroupDefaultValueTimeInput
                          v-else-if="['TIME'].includes(getQuestionType(item))"
                          :item="item"
                          v-model="item.value"
                        />
                        <template
                          v-else-if="
                            ['IMAGE'].includes(getInformationType(item))
                          "
                        >
                          <EditInfoImageDialog
                            v-if="isDynamic(item)"
                            v-model="row[itemIndex]"
                          >
                            <div
                              class="primary--text px-0 mx-0 d-flex align-center"
                              v-if="getInfoImageFileName(item)"
                            >
                              <v-btn
                                text
                                color="primary"
                                class="px-0 mx-0"
                                min-width="0"
                              >
                                <v-icon class="mr-1">
                                  {{ mdiImagePlus }}
                                </v-icon>
                                {{ getInfoImageFileName(item) }}
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-btn
                                text
                                color="primary"
                                class="px-0 mx-0"
                                min-width="0"
                              >
                                <v-icon class="mr-1">
                                  {{ mdiImagePlus }}
                                </v-icon>
                                Add Image
                              </v-btn>
                            </div>
                          </EditInfoImageDialog>
                          <section v-else>Not Editable</section>
                        </template>
                        <section v-else>Not Editable</section>
                      </div>
                    </td>
                    <td class="px-1">
                      <CreateConditionDialog
                        :formDefinition="formDefinition"
                        v-model="repeatingGroupDefaultValues[index]"
                      >
                        <div class="primary--text px-3">
                          <b>
                            {{ getCreateCondition(row) }}
                          </b>
                        </div>
                      </CreateConditionDialog>
                    </td>
                    <td>
                      <div class="d-flex">
                        <v-btn
                          icon
                          text
                          @click="copyRow(index)"
                          color="primary"
                        >
                          <v-icon>
                            {{ mdiContentCopy }}
                          </v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          text
                          @click="
                            selectedRowIndex = index;
                            showConfirmDeleteRowDialog = true;
                          "
                          color="primary"
                        >
                          <v-icon>
                            {{ mdiDelete }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <section v-else class="d-flex justify-center align-center">
              No Group Items Created
            </section>

            <v-btn text color="primary" class="px-0 mx-0" @click="createRow">
              <v-icon style="margin-top: -4px">
                {{ mdiPlus }}
              </v-icon>
              <div>Add Row</div>
            </v-btn>
          </validation-observer>
        </v-responsive>

        <ConfirmDeleteRowDialog
          :showConfirmDeleteRowDialog="showConfirmDeleteRowDialog"
          :selectedRowIndex="selectedRowIndex"
          @cancel="showConfirmDeleteRowDialog = false"
          @delete="deleteRow"
        />
      </v-card-text>

      <v-card-actions class="px-5 py-3 d-flex justify-end">
        <v-btn color="primary" @click="saveDefaultValues">
          Save Default Values
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiDelete,
  mdiContentCopy,
  mdiClockOutline,
  mdiCalendarBlankOutline,
  mdiImagePlus,
  mdiPlus,
} from "@mdi/js";
import { cloneDeep } from "lodash";
import CreateConditionDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/advanced-default-settings-dialog/repeating-group-default-values-dialog/CreateConditionDialog.vue";
import ConfirmDeleteRowDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/advanced-default-settings-dialog/repeating-group-default-values-dialog/ConfirmDeleteRowDialog.vue";
import EditInfoImageDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/advanced-default-settings-dialog/repeating-group-default-values-dialog/EditInfoImageDialog.vue";
import RepeatinghGroupDefaultValueDateInput from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/advanced-default-settings-dialog/repeating-group-default-values-dialog/RepeatinghGroupDefaultValueDateInput.vue";
import RepeatinghGroupDefaultValueTimeInput from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/advanced-default-settings-dialog/repeating-group-default-values-dialog/RepeatinghGroupDefaultValueTimeInput.vue";
import { CONDITION_CHOICES } from "@/constants/advancedSettings";

export default {
  name: "RepeatingGroupDefaultValuesDialog",
  props: {
    showRepeatingGroupDefaultValuesDialog: Boolean,
    selectedItemOptions: Object,
    value: Array,
    formDefinition: Object,
    addOrDeleteRowsSetting: Boolean,
  },
  data() {
    return {
      mdiClose,
      mdiDelete,
      mdiContentCopy,
      mdiClockOutline,
      mdiCalendarBlankOutline,
      mdiImagePlus,
      mdiPlus,
      repeatingGroupDefaultValues: [],
      isEditing: {},
      showConfirmDeleteRowDialog: false,
      selectedRowIndex: undefined,
      addOrDeleteRows: undefined,
    };
  },
  components: {
    CreateConditionDialog,
    ConfirmDeleteRowDialog,
    EditInfoImageDialog,
    RepeatinghGroupDefaultValueDateInput,
    RepeatinghGroupDefaultValueTimeInput,
  },
  computed: {
    repeatingGroupTableHeadings() {
      const [firstRow] = this.repeatingGroupDefaultValues;
      return (
        firstRow?.map((g) => {
          const { id } = g;
          let label;
          if (g?.information?.type === "IMAGE") {
            label = "Info Image";
          } else {
            label = g?.question?.label ?? g?.information?.label;
          }
          return {
            id,
            label,
            type: g?.question?.type ?? g?.information?.type,
            isQuestion: g?.question,
            isInfo: g?.information,
            isEditable: this.isEditable(g),
          };
        }) ?? []
      );
    },
  },
  methods: {
    async createRow() {
      const mappedGroupedItems =
        this.selectedItemOptions?.question?.groupedItems?.map((g) => {
          const { id, question, information, value } = g;
          if (question) {
            if (!question.create) {
              question.create = {
                condition: "ALWAYS",
                dependent: undefined,
                dependentFieldId: undefined,
                value: undefined,
              };
            }
            return { id, question, value };
          } else if (information) {
            if (!information.create) {
              information.create = {
                condition: "ALWAYS",
                dependent: undefined,
                dependentFieldId: undefined,
                value: undefined,
              };
            }
            return { id, information, value };
          }
          return g;
        });
      this.repeatingGroupDefaultValues.push(mappedGroupedItems);
      await this.$nextTick();
      this.isEditing = {};
      const index = this.repeatingGroupDefaultValues.length - 1;
      this.$set(this.isEditing, index, !this.isEditing[index]);
      await this.$nextTick();
      if (Array.isArray(this.$refs.input)) {
        const lastInput = this.$refs.input.at(-1);
        lastInput.$refs.input.focus();
      }
    },
    onNumberKeyDown(e) {
      if (["e", "+", "*", "/"].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }
    },
    isEditable(item) {
      return (
        [
          "SINGLE_SELECT",
          "MULTI_SELECT",
          "TEXT",
          "EMAIL",
          "NUMBER",
          "DATE",
          "TIME",
        ].includes(this.getQuestionType(item)) ||
        ["IMAGE"].includes(this.getInformationType(item))
      );
    },
    deleteRow(selectedRowIndex) {
      this.repeatingGroupDefaultValues.splice(selectedRowIndex, 1);
      this.showConfirmDeleteRowDialog = false;
      this.selectedRowIndex = undefined;
    },
    getQuestionType(item) {
      return item?.question?.type;
    },
    getInformationType(item) {
      return item?.information?.type;
    },
    isDynamic(item) {
      return item?.information?.dynamic;
    },
    getQuestionOptions(item) {
      return item?.question?.options ?? [];
    },
    getCreateCondition(row) {
      const [item] = row ?? [];
      const createCondition =
        item?.question?.create ?? item?.information?.create;

      if (createCondition?.condition === CONDITION_CHOICES.ALWAYS) {
        return createCondition?.condition;
      }
      return CONDITION_CHOICES.CONDITIONAL;
    },
    getInfoImageFileName(item) {
      return item?.information?.url?.split("/")?.at(-1);
    },
    copyRow(index) {
      const selectedRow = cloneDeep(this.repeatingGroupDefaultValues[index]);
      this.repeatingGroupDefaultValues = [
        ...this.repeatingGroupDefaultValues.slice(0, index),
        selectedRow,
        ...this.repeatingGroupDefaultValues.slice(index),
      ];
    },
    async saveDefaultValues() {
      const success =
        await this.$refs.repeatingGroupDefaultValuesForm.validate();
      if (!success) {
        return;
      }

      this.$emit("input", cloneDeep(this.repeatingGroupDefaultValues));
      this.$emit("addOrDeleteRows", this.addOrDeleteRows);
      await this.$nextTick();
      this.$emit("repeating-group-default-values-dialog-close");
    },
  },
  beforeMount() {
    if (!("addOrDeleteRows" in this.selectedItemOptions.question)) {
      this.addOrDeleteRows = true;
    } else {
      this.addOrDeleteRows = this.selectedItemOptions.question.addOrDeleteRows;
    }
    if (!Array.isArray(this.value)) {
      const mappedGroupedItems =
        this.selectedItemOptions?.question?.groupedItems?.map((g) => {
          const { id, question, information, value } = g;
          if (question) {
            if (!question.create) {
              question.create = {
                condition: "ALWAYS",
                dependent: undefined,
                dependentFieldId: undefined,
                value: undefined,
              };
            }
            return { id, question, value };
          } else if (information) {
            if (!information.create) {
              information.create = {
                condition: "ALWAYS",
                dependent: undefined,
                dependentFieldId: undefined,
                value: undefined,
              };
            }
            return { id, information, value };
          }
          return g;
        });
      this.repeatingGroupDefaultValues = [cloneDeep(mappedGroupedItems ?? [])];
    } else {
      const newValues = cloneDeep(this.value).map((row) => {
        return this.selectedItemOptions?.question?.groupedItems?.map((g) => {
          const existingItem = cloneDeep(
            row.find((item) => +item.id === +g.id) ?? g
          );
          const { question, information } = g;
          if (
            ["SINGLE_SELECT", "MULTI_SELECT"].includes(
              this.getQuestionType(existingItem)
            )
          ) {
            existingItem.question.options = [...question.options];
          }

          if (["IMAGE"].includes(this.getInformationType(existingItem))) {
            existingItem.information.dynamic = information.dynamic;
            if (!existingItem.information.dynamic) {
              existingItem.information = {
                ...existingItem.information,
                url: "",
                openUrl: "",
              };
            }
          }

          if (existingItem.question) {
            if (!existingItem.question?.create) {
              existingItem.question.create = {
                condition: "ALWAYS",
                dependent: undefined,
                dependentFieldId: undefined,
                value: undefined,
              };
            }
          } else if (existingItem.information) {
            if (!existingItem.information?.create) {
              existingItem.information.create = {
                condition: "ALWAYS",
                dependent: undefined,
                dependentFieldId: undefined,
                value: undefined,
              };
            }
          }
          return existingItem ?? g;
        });
      });
      this.repeatingGroupDefaultValues = cloneDeep(newValues);
    }
  },
};
</script>

<style scoped>
td,
th {
  min-width: 75px;
  text-align: left;
}

.raw-html >>> p {
  margin-bottom: 0;
}
</style>

<template>
  <v-card class="elevation-2">
    <v-card-text class="px-6 pt-0">
      <v-row>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Location"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.Location
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="District"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.District
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" md="6">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Shop"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.Shop
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Map Page"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.MapPage
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" md="6">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Tile Number"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.TileNo
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Facility ID"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.Facility_Id
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>

        <v-col cols="12" md="6">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Level ID"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.Level_Id
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "CityworksLocationForm",
  props: {
    value: Object,
  },
  data() {
    return {
      formDefinition: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.formDefinition);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (
          !this.formDefinition.form.city_works_webhook_config.on_form_submission
            .inspection_comments
        ) {
          this.formDefinition.form.city_works_webhook_config.on_form_submission.inspection_comments =
            {
              ForemanRecomnd: null,
              ObservationSum: null,
              RepairsMade: null,
              CondRating: null,
            };
        }
      },
    },
  },
};
</script>

<template>
  <v-card class="elevation-2">
    <v-card-text
      class="pl-6 pr-6"
      :class="
        formDefinition.form.city_works_webhook_config.on_form_submission
          .close_activity_condition.condition === 'Conditional'
          ? 'pb-2'
          : 'pb-7'
      "
    >
      <v-row>
        <v-col cols="12" class="pb-0 my-0">
          <v-switch
            class="py-0 my-0"
            v-model="
              formDefinition.form.city_works_webhook_config.on_form_creation
                .enabled
            "
            label="Create Cityworks Activity if not already created"
            @change="$emit('input', formDefinition)"
          ></v-switch>
        </v-col>

        <v-col cols="12" class="py-0 my-0">
          <v-switch
            class="py-0 my-0"
            v-model="
              formDefinition.form.city_works_webhook_config.on_form_submission
                .attach_PDF
            "
            label="Attach PDF"
            @change="$emit('input', formDefinition)"
          ></v-switch>
        </v-col>

        <v-col cols="12" class="py-0 my-0">
          <v-switch
            class="py-0 my-0"
            v-model="
              formDefinition.form.city_works_webhook_config.on_form_submission
                .attach_photos
            "
            label="Attach Photos"
            @change="$emit('input', formDefinition)"
          ></v-switch>
        </v-col>
        <v-col cols="12" class="pt-3 pb-0 my-0">
          <v-row>
            <v-col cols="12" md="3" class="py-0 my-0">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Close Cityworks Activity"
              >
                <v-select
                  label="Close Cityworks Activity"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="closeCityworksActivity"
                  :items="activityOptions"
                  item-text="label"
                  item-value="value"
                  v-model="
                    formDefinition.form.city_works_webhook_config
                      .on_form_submission.close_activity_condition.condition
                  "
                  @change="$emit('input', formDefinition)"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col
              class="py-0 my-0"
              cols="12"
              md="3"
              v-if="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .close_activity_condition.condition === 'Conditional'
              "
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Form Field"
                rules="required"
              >
                <v-select
                  label="Form Field"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="formField"
                  :items="questionChoices"
                  item-text="label"
                  item-value="value"
                  v-model="
                    formDefinition.form.city_works_webhook_config
                      .on_form_submission.close_activity_condition.questionID
                  "
                  @change="$emit('input', formDefinition)"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col
              class="py-0 my-0"
              cols="12"
              md="3"
              v-if="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .close_activity_condition.condition === 'Conditional'
              "
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Condition"
                rules="required"
              >
                <v-select
                  label="Condition"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="questionCondition"
                  :items="questionConditionOptions"
                  item-text="label"
                  item-value="value"
                  v-model="
                    formDefinition.form.city_works_webhook_config
                      .on_form_submission.close_activity_condition
                      .question_condition
                  "
                  @change="$emit('input', formDefinition)"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col
              class="py-0 my-0"
              cols="12"
              md="3"
              v-if="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .close_activity_condition.condition === 'Conditional'
              "
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Value"
                :rules="{
                  required: true,
                  numeric: questionType === 'NUMBER',
                }"
              >
                <v-text-field
                  v-if="questionType !== 'SINGLE_SELECT'"
                  label="Value"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="value"
                  :type="questionType === 'NUMBER' ? 'number' : ''"
                  item-text="label"
                  item-value="value"
                  v-model="
                    formDefinition.form.city_works_webhook_config
                      .on_form_submission.close_activity_condition.value
                  "
                  @input="$emit('input', formDefinition)"
                >
                </v-text-field>
                <v-select
                  v-else
                  label="Value"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="value"
                  item-text="label"
                  item-value="value"
                  v-model="
                    formDefinition.form.city_works_webhook_config
                      .on_form_submission.close_activity_condition.value
                  "
                  :items="singleSelectOptions"
                  @change="$emit('input', formDefinition)"
                >
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";

const activityOptions = [
  { value: "Always", label: "Always" },
  { value: "Never", label: "Never" },
  { value: "Conditional", label: "Conditional" },
];

const textConditionOptions = [
  { value: "Equal", label: "Equal" },
  { value: "Not Equal", label: "Not Equal" },
];

const numberConditionOptions = [
  { value: "Greater Than", label: "Greater Than" },
  { value: "Less Than", label: "Less Than" },
  { value: "Greater Than or Equal", label: "Greater Than or Equal" },
  { value: "Less Than or Equal", label: "Less Than or Equal" },
];

export default {
  name: "CityworksActivityForm",
  props: {
    value: Object,
  },
  mixins: [questionsMixin],
  computed: {
    questionChoices() {
      const { questions } = this;
      return questions.map(({ id, number, question: { label } = {} }) => ({
        value: id,
        label: `${number} ${label}`,
      }));
    },
    questionConditionOptions() {
      const { questionID } =
        this.formDefinition.form.city_works_webhook_config.on_form_submission
          .close_activity_condition;
      const { questions } = this;
      const question = questions.find(({ id }) => +id === +questionID);
      if (!question) {
        return [];
      }
      if (question.question && question.question.type === "NUMBER") {
        return [...textConditionOptions, ...numberConditionOptions];
      }
      return [...textConditionOptions];
    },
    questionType() {
      const { questionID } =
        this.formDefinition.form.city_works_webhook_config.on_form_submission
          .close_activity_condition;
      const { questions } = this;
      const question = questions.find(({ id }) => +id === +questionID);
      if (question && question.question) {
        return question.question.type;
      } else {
        return "";
      }
    },
    singleSelectOptions() {
      const { questionID } =
        this.formDefinition.form.city_works_webhook_config.on_form_submission
          .close_activity_condition;
      const { questions } = this;
      const question = questions.find(({ id }) => +id === +questionID);
      if (question && question.question) {
        return question.question.options;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      formDefinition: {},
      activityOptions,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (
          !this.formDefinition.form.city_works_webhook_config.on_form_creation
        ) {
          this.formDefinition.form.city_works_webhook_config.on_form_creation =
            {
              enabled: false,
            };
        }
      },
    },
  },
};
</script>

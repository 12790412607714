<template>
  <v-card class="elevation-0 mx-auto" max-width="600" color="#e5e5e5">
    <v-card-text class="pa-0 card-background">
      <v-tabs background-color="#f1f2f1" v-model="tab">
        <v-tab href="#to-cityworks"> To Cityworks </v-tab>
        <v-tab href="#from-cityworks"> From Cityworks </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item class="card-background" value="to-cityworks">
          <div class="ma-2 ml-0 mr-0 card-background">
            <v-switch
              v-model="formDefinition.form.city_works_webhook_config.enabled"
              label="Enable To Cityworks UtiliBot"
              @change="$emit('input', formDefinition)"
            ></v-switch>
          </div>

          <div v-if="formDefinition.form.city_works_webhook_config.enabled">
            <v-row>
              <v-col cols="12">
                <p class="caption"><b>Template</b></p>
                <TemplateForm
                  :key="hasValidCityworksToken"
                  v-model="formDefinition"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>

              <v-col cols="12">
                <p class="caption"><b>Cityworks Activity</b></p>
                <CityworksActivityForm
                  v-model="formDefinition"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>

              <v-col cols="12" v-if="activityType">
                <p class="caption" v-if="activityType === 'Inspection'">
                  <b>Comments</b>
                </p>
                <p class="caption" v-else>
                  <b>Comments and Instructions</b>
                </p>
                <CityworksCommentsForm
                  v-model="formDefinition"
                  :activityType="activityType"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>

              <v-col cols="12" v-if="activityType === 'Inspection'">
                <p class="caption">
                  <b>Location</b>
                </p>
                <CityworksLocationForm
                  v-model="formDefinition"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>

              <v-col cols="12" v-if="activityType === 'Inspection'">
                <p class="caption"><b>Observation Mappings</b></p>
                <CityworksObservationMappings
                  v-model="formDefinition"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>

              <v-col cols="12">
                <p class="caption">
                  <b>Create Cityworks Activities On Form Submission</b>
                </p>
                <CityworksActivities
                  v-model="formDefinition"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>
            </v-row>
          </div>
        </v-tab-item>

        <v-tab-item class="card-background" value="from-cityworks">
          <div class="ma-2 ml-0 mr-0 card-background">
            <v-switch
              v-model="
                formDefinition.form.city_works_webhook_config.create_task
                  .enabled
              "
              label="Enable From Cityworks UtiliBot"
              @change="$emit('input', formDefinition)"
            ></v-switch>

            <v-row>
              <v-col cols="12">
                <p class="caption">
                  <b>Configuration</b>
                </p>

                <FromCityworksConfigurationForm
                  v-model="formDefinition"
                  @input="$emit('input', formDefinition)"
                />
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
      </v-tabs-items>

      <UserCityworksSignInDialog
        v-if="showUserCityworksSignInDialog"
        :cityworksIntegrationInfo="cityworksIntegrationInfo"
        :showUserCityworksSignInDialog="showUserCityworksSignInDialog"
        @cityworks-sign-in-dialog-close="showUserCityworksSignInDialog = false"
        @cityworks-sign-in-dialog-succeed="
          showUserCityworksSignInDialog = false;
          validateCityworksToken();
        "
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import TemplateForm from "@/components/forms/form-builder-edit-form/cityworks-form/TemplateForm";
import CityworksActivityForm from "@/components/forms/form-builder-edit-form/cityworks-form/CityworksActivityForm";
import CityworksCommentsForm from "@/components/forms/form-builder-edit-form/cityworks-form/CityworksCommentsForm";
import CityworksObservationMappings from "@/components/forms/form-builder-edit-form/cityworks-form/CityworksObservationMappings";
import CityworksActivities from "@/components/forms/form-builder-edit-form/cityworks-form/CityworksActivities";
import CityworksLocationForm from "@/components/forms/form-builder-edit-form/cityworks-form/CityworksLocationForm";
import axios from "axios";
import authObjectMixin from "@/mixins/authObjectMixin";
import UserCityworksSignInDialog from "@/components/shared/UserCityworksSignInDialog.vue";
import FromCityworksConfigurationForm from "@/components/forms/form-builder-edit-form/cityworks-form/FromCityworksConfigurationForm";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "CityworksForm",
  props: {
    value: Object,
  },
  components: {
    TemplateForm,
    CityworksActivityForm,
    CityworksCommentsForm,
    CityworksObservationMappings,
    CityworksActivities,
    CityworksLocationForm,
    FromCityworksConfigurationForm,
    UserCityworksSignInDialog,
  },
  mixins: [authObjectMixin],

  data() {
    return {
      formDefinition: {},
      showUserCityworksSignInDialog: false,
      tab: "user",
      hasValidCityworksToken: false,
      cityworksIntegrationInfo: {},
    };
  },
  computed: {
    activityType() {
      return this.formDefinition.form.city_works_webhook_config.type;
    },
  },
  methods: {
    async validateCityworksToken() {
      const auth = JSON.parse(localStorage.getItem("auth")) ?? {};
      const { cityworks_token: cityworksToken } = auth ?? {};
      if (!cityworksToken) {
        this.showUserCityworksSignInDialog = true;
        this.hasValidCityworksToken = false;
        return;
      }

      try {
        const {
          data: { error },
        } = await axios.get(`${APIURL}/cityworks/validate_token`);
        if (error) {
          this.showUserCityworksSignInDialog = true;
        } else {
          this.hasValidCityworksToken = true;
        }
      } catch (error) {
        this.showUserCityworksSignInDialog = true;
        this.hasValidCityworksToken = false;
      } finally {
        if (this.cityworksAuthInvalid) {
          // eslint-disable-next-line no-unused-vars
          const { cityworks_token, ...newAuth } = auth;
          localStorage.setItem("auth", JSON.stringify(newAuth));
        }
      }
    },
    async getCityworksUserGroupInfo() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/user_group_info`);
      this.cityworksIntegrationInfo = results;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (!this.formDefinition.form.city_works_webhook_config) {
          this.formDefinition.form.city_works_webhook_config = {
            on_form_submission: {
              close_activity_condition: {},
              create_new_activities: [],
              observations: [],
              work_order_comment: null,
              inspection_comments: {},
              attach_PDF: false,
              attach_photos: false,
            },
            on_form_creation: {},
            create_task: {},
          };
        }

        if (!this.formDefinition.form?.city_works_webhook_config?.create_task) {
          this.formDefinition.form.city_works_webhook_config.create_task = {};
        }
      },
    },
  },
  async mounted() {
    await this.validateCityworksToken();
    await this.getCityworksUserGroupInfo();
  },
};
</script>

<style scoped>
.card-background {
  background-color: #f1f2f1;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showActionItemDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Action Item")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.closeForm } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-3" },
            [
              _c(
                "validation-observer",
                { ref: "actionItemForm" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Description", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ errors, valid }) {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Description *",
                                        "hide-details": "auto",
                                        "error-messages": errors,
                                        success: valid,
                                        color: "primary",
                                        name: "value",
                                        disabled: _vm.readOnly,
                                        hint: _vm.item.question.descriptionHint,
                                        "persistent-hint": "",
                                      },
                                      model: {
                                        value:
                                          _vm.actionItem
                                            .action_item_description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.actionItem,
                                            "action_item_description",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "actionItem.action_item_description",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex gap justify-space-between",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "half" },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "Assigned To",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.userChoices,
                                            "hide-details": "auto",
                                            name: "assignedTo",
                                            "item-value": "value",
                                            "item-text": "name",
                                            "error-messages": errors,
                                            success: valid,
                                            label: "Assigned To *",
                                            disabled: _vm.readOnly,
                                          },
                                          model: {
                                            value: _vm.assigneeUserOrFieldId,
                                            callback: function ($$v) {
                                              _vm.assigneeUserOrFieldId = $$v
                                            },
                                            expression: "assigneeUserOrFieldId",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm.assigneeUserOrFieldId === "manually-add-email"
                            ? _c(
                                "div",
                                { staticClass: "half" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Email",
                                      rules: { required: true, email: true },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Enter Email *",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "email",
                                                  disabled: _vm.readOnly,
                                                  "persistent-hint": "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.manuallyEnteredAssigneeEmail,
                                                  callback: function ($$v) {
                                                    _vm.manuallyEnteredAssigneeEmail =
                                                      $$v
                                                  },
                                                  expression:
                                                    "manuallyEnteredAssigneeEmail",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3768184136
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "half" },
                          [
                            _c("validation-provider", {
                              attrs: {
                                slim: "",
                                name: _vm.item.question.label,
                                rules: "required",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            label: "Due By *",
                                                            color: "primary",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            disabled:
                                                              _vm.readOnly,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.actionItem
                                                                .due_by,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.actionItem,
                                                                "due_by",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "actionItem.due_by",
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("v-date-picker", {
                                            model: {
                                              value: _vm.actionItem.due_by,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.actionItem,
                                                  "due_by",
                                                  $$v
                                                )
                                              },
                                              expression: "actionItem.due_by",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("p", { staticClass: "caption" }, [
                            _vm._v("Photos"),
                          ]),
                          _c("file-pond", {
                            ref: "files",
                            attrs: {
                              name: "files",
                              "label-idle": "Tap or drop photos here...",
                              "allow-multiple": "",
                              "accepted-file-types": "image/jpeg, image/png",
                              files: _vm.uploadFiles,
                              allowFileSizeValidation: "",
                              maxFileSize: "2MB",
                              disabled: _vm.readOnly,
                            },
                            on: { addfile: _vm.onChange },
                          }),
                          _vm._l(
                            _vm.actionItem.assigner_photos,
                            function (image, index) {
                              return _c(
                                "v-card",
                                { key: image.id },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row justify-start elevation-0",
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "elevation-0 d-flex flex-column justify-center mr-2",
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "thumbnail mr-2",
                                                        attrs: {
                                                          src: image.url,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "elevation-0",
                                                      attrs: { width: "100%" },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        key: image.id,
                                                        attrs: {
                                                          label: "Description",
                                                          color: "primary",
                                                          name: image.id,
                                                        },
                                                        model: {
                                                          value:
                                                            image.description,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              image,
                                                              "description",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "image.description",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c(
                                                "v-list-item-action-text",
                                                [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.mdiDotsVertical
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removePhoto(
                                                                      index,
                                                                      image.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.mdiDelete
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    " Delete "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5" },
            [
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [_vm._v("Save Action Item")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "elevation-2" },
    [
      _c(
        "v-card-text",
        { staticClass: "pl-6 pr-6" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Activity Type" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-select", {
                              attrs: {
                                label: "Activity Type",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                                name: "activityType",
                                items: _vm.activityTypes,
                                "item-text": "label",
                                "item-value": "value",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChange(
                                    "type",
                                    _vm.copiedFormDefinition.form
                                      .city_works_webhook_config.type
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.copiedFormDefinition.form
                                    .city_works_webhook_config.type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.copiedFormDefinition.form
                                      .city_works_webhook_config,
                                    "type",
                                    $$v
                                  )
                                },
                                expression:
                                  "copiedFormDefinition.form.city_works_webhook_config.type",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Domain" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            !_vm.cityworksAuthInvalid
                              ? _c("v-select", {
                                  attrs: {
                                    label: "Domain",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "domain",
                                    items: _vm.domainChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "domain",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config.domain
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.domain,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "domain",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.domain\n            ",
                                  },
                                })
                              : _c("v-text-field", {
                                  attrs: {
                                    label: "Domain",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "domain",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "domain",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config.domain
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.domain,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "domain",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.domain\n            ",
                                  },
                                }),
                            _vm.getDomainsError || _vm.cityworksAuthInvalid
                              ? _c(
                                  "section",
                                  {
                                    class: {
                                      "red--text": !_vm.cityworksAuthInvalid,
                                      "orange--text": _vm.cityworksAuthInvalid,
                                    },
                                  },
                                  [
                                    !_vm.cityworksAuthInvalid
                                      ? [
                                          _vm._v(
                                            " Error domains: " +
                                              _vm._s(_vm.getDomainsError) +
                                              " "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            " A list of values is not available because you are not signed in to Cityworks "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Entity Group" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            !_vm.cityworksAuthInvalid
                              ? _c("v-select", {
                                  attrs: {
                                    label: "Entity Group",
                                    "hide-details": "",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "entityGroup",
                                    items: _vm.entityGroupChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "entity_group",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config
                                          .entity_group
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.entity_group,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "entity_group",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.entity_group\n            ",
                                  },
                                })
                              : _c("v-text-field", {
                                  attrs: {
                                    label: "Entity Group",
                                    "hide-details": "",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "entityGroup",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "entity_group",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config
                                          .entity_group
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.entity_group,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "entity_group",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.entity_group\n            ",
                                  },
                                }),
                            _vm.getEntityGroupsError || _vm.cityworksAuthInvalid
                              ? _c(
                                  "section",
                                  {
                                    class: {
                                      "red--text": !_vm.cityworksAuthInvalid,
                                      "orange--text": _vm.cityworksAuthInvalid,
                                    },
                                  },
                                  [
                                    !_vm.cityworksAuthInvalid
                                      ? [
                                          _vm._v(
                                            " Error getting entity groups: " +
                                              _vm._s(_vm.getEntityGroupsError) +
                                              " "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            " A list of values is not available because you are not signed in to Cityworks "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Entity Type" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            !_vm.cityworksAuthInvalid
                              ? _c("v-select", {
                                  attrs: {
                                    label: "Entity Type",
                                    "hide-details": "",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "entityType",
                                    items: _vm.entityTypeChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "entity_type",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config.entity_type
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.entity_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "entity_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.entity_type\n            ",
                                  },
                                })
                              : _c("v-text-field", {
                                  attrs: {
                                    label: "Entity Type",
                                    "hide-details": "",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "entityType",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "entity_type",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config.entity_type
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.entity_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "entity_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.entity_type\n            ",
                                  },
                                }),
                            _vm.getEntityTypesError || _vm.cityworksAuthInvalid
                              ? _c(
                                  "section",
                                  {
                                    class: {
                                      "red--text": !_vm.cityworksAuthInvalid,
                                      "orange--text": _vm.cityworksAuthInvalid,
                                    },
                                  },
                                  [
                                    !_vm.cityworksAuthInvalid
                                      ? [
                                          _vm._v(
                                            " Error getting entity types: " +
                                              _vm._s(_vm.getEntityTypesError) +
                                              " "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            " A list of values is not available because you are not signed in to Cityworks "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Template" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            !_vm.cityworksAuthInvalid
                              ? _c("v-select", {
                                  attrs: {
                                    label: "Template",
                                    "hide-details": "",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "templateId",
                                    items: _vm.templateChoices,
                                    "item-text": "label",
                                    "item-value": "value",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "template_id",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config.template_id
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.template_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "template_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.template_id\n            ",
                                  },
                                })
                              : _c("v-text-field", {
                                  attrs: {
                                    label: "Template",
                                    "hide-details": "",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "templateId",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChange(
                                        "template_id",
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config.template_id
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.copiedFormDefinition.form
                                        .city_works_webhook_config.template_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.copiedFormDefinition.form
                                          .city_works_webhook_config,
                                        "template_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "\n              copiedFormDefinition.form.city_works_webhook_config.template_id\n            ",
                                  },
                                }),
                            _vm.getInspectionTemplatesError ||
                            _vm.getWorkOrderTemplatesError ||
                            _vm.cityworksAuthInvalid
                              ? _c(
                                  "section",
                                  {
                                    class: {
                                      "red--text": !_vm.cityworksAuthInvalid,
                                      "orange--text": _vm.cityworksAuthInvalid,
                                    },
                                  },
                                  [
                                    !_vm.cityworksAuthInvalid &&
                                    _vm.getInspectionTemplatesError
                                      ? [
                                          _vm._v(
                                            " Error getting Template: " +
                                              _vm._s(
                                                _vm.getInspectionTemplatesError
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : !_vm.cityworksAuthInvalid &&
                                        _vm.getWorkOrderTemplatesError
                                      ? [
                                          _vm._v(
                                            " Error getting Template: " +
                                              _vm._s(
                                                _vm.getWorkOrderTemplatesError
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            " A list of values is not available because you are not signed in to Cityworks "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title>
        {{ typeof this.index !== "number" ? "Add" : "Edit" }}
        Choice
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('choice-form-close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <validation-observer ref="form">
        <form @submit.prevent="saveChoice">
          <v-row>
            <v-col cols="12">
              <template v-if="isImage">
                <file-pond
                  name="files"
                  ref="files"
                  label-idle="Tap or drop photos here..."
                  :allow-multiple="false"
                  accepted-file-types="image/jpeg, image/png"
                  :files="selectedFile"
                  @addfile="onFileChange"
                  allowFileSizeValidation
                  maxFileSize="2MB"
                />
              </template>
              <template v-else>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Value"
                  rules="required"
                >
                  <v-text-field
                    v-model="choice.value"
                    label="Value"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    name="value"
                  />
                </validation-provider>
              </template>
            </v-col>

            <v-col cols="12">
              <validation-provider v-slot="{ errors, valid }" name="Label">
                <v-text-field
                  v-model="choice.label"
                  label="Label (Optional)"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <validation-provider v-slot="{ errors, valid }" name="Visible">
                <v-select
                  v-model="visibleCondition"
                  :items="visibilityOptions"
                  label="Visible"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  hide-details
                ></v-select>
              </validation-provider>

              <ComparisonConditionForm
                v-if="['conditional'].includes(visibleCondition)"
                v-model="choice.visible"
                :formDefinition="formDefinition"
                :selectedItem="selectedItem"
              />
            </v-col>

            <v-col cols="12" class="d-flex justify-end">
              <v-btn text type="submit">Save Choice</v-btn>
            </v-col>
          </v-row>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { validate } from "vee-validate";
import ComparisonConditionForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/ComparisonConditionForm";
import { cloneDeep } from "lodash";
import { visibilityOptions } from "@/constants/choices";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "ChoiceForm",
  props: {
    option: Object,
    index: Number,
    selectedItemOptions: Object,
    selectedItem: Object,
    formDefinition: Object,
  },
  data() {
    return {
      choice: {
        id: uuidv4(),
      },
      selectedFile: undefined,
      visibleCondition: {},
      visibilityOptions,
    };
  },
  components: {
    FilePond,
    ComparisonConditionForm,
  },
  computed: {
    isImage() {
      return this.selectedItemOptions?.question?.isImage;
    },
  },
  methods: {
    async saveChoice() {
      const { valid: imageValid } = await validate(
        this.choice.value,
        "required"
      );
      const success = await this.$refs.form.validate();
      if (!imageValid || !success) {
        return;
      }

      if (typeof this.index !== "number") {
        this.$emit("choice-submitted", this.choice);
      } else {
        this.$emit("choice-submitted", this.choice, this.index);
      }
    },
    async onFileChange() {
      const files = this.$refs.files.getFiles();
      if (files.length === 0) {
        return;
      }
      const [{ file }] = files;
      this.choice.value = await toBase64(file);
    },
    async getSelectedChoice() {
      this.choice = cloneDeep(this.option) ?? {};

      if (
        this.choice?.visible &&
        !["NEVER", "ALWAYS"].includes(this.choice?.visible?.condition)
      ) {
        this.visibleCondition = "conditional";
      } else {
        this.visibleCondition = this.choice?.visible?.condition;
      }

      if (!this.choice.value) {
        return;
      }
      const res = await fetch(this.choice.value);
      const blob = await res.blob();
      const { value } = this.choice;
      const [fileInfo] = value.split(";");
      if (!fileInfo) {
        return;
      }
      const [, type] = fileInfo.split(":");
      if (!type) {
        return;
      }
      const [, extension] = type.split("/");
      const file = new File([blob], `image.${extension}`, { type });
      this.selectedFile = [file];
    },
  },
  beforeMount() {
    this.getSelectedChoice();
  },
};
</script>

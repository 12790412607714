<template>
  <div>
    <v-btn
      text
      color="primary"
      class="px-0 mx-0"
      @click="showInsertedInDoc = !showInsertedInDoc"
      v-if="hasFormFieldInsertedCount"
    >
      Show Inserted in Doc
    </v-btn>

    <validation-provider v-slot="{ errors, valid }" name="Search">
      <v-text-field
        v-model="searchField"
        label="Search"
        hide-details="auto"
        :error-messages="errors"
        :success="valid"
        color="primary"
        name="searchField"
      >
        <template v-slot:append>
          <v-icon>{{ mdiMagnify }}</v-icon>
        </template>
      </v-text-field>
    </validation-provider>

    <v-list class="pa-0 my-0">
      <v-list-item
        v-for="f of filteredFormFields"
        :key="f.id"
        @click="$emit('form-field-selected', f.id)"
        class="pa-0 my-0"
      >
        <v-list-item-content class="pa-0 my-0">
          <div
            class="caption"
            v-if="showInsertedInDoc"
            :class="{
              'primary--text': formFieldInsertedCount[f.id] > 0,
              'yellow--text text--darken-4':
                (formFieldInsertedCount[f.id] || 0) === 0,
            }"
          >
            Inserted in Doc {{ formFieldInsertedCount[f.id] || 0 }} Times
          </div>
          <div>ID:{{ f.id }} - {{ f.question.label }}</div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";

export default {
  name: "FormFieldsForm",
  props: {
    formDefinition: Object,
  },
  computed: {
    formFields() {
      return this.formDefinition.form.sections
        .map((s) => s.items)
        .flat()
        .filter((item) => item.type === "QUESTION");
    },
    filteredFormFields() {
      if (!this.searchField) {
        return this.formFields;
      }
      return this.formFields.filter((f) => {
        return f?.question?.label
          ?.toLowerCase?.()
          ?.includes(this.searchField?.toLowerCase());
      });
    },
    formFieldInsertedCount() {
      const headerTemplateHtml =
        this.formDefinition.html_merge_definition_header;
      const bodyTemplateHtml = this.formDefinition.html_merge_definition;
      const footerTemplateHtml =
        this.formDefinition.html_merge_definition_footer;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML =
        headerTemplateHtml + bodyTemplateHtml + footerTemplateHtml;
      const fieldCountEntries = this.formFields.map(({ id }) => {
        const numItemsInserted = tempDiv.querySelectorAll(
          `.form-field-${id}`
        ).length;
        return [id, numItemsInserted];
      });
      const fieldCounts = Object.fromEntries(fieldCountEntries);
      return fieldCounts;
    },
    hasFormFieldInsertedCount() {
      return (
        Object.values(this.formFieldInsertedCount).filter(Boolean).length > 0
      );
    },
  },
  data() {
    return {
      searchField: "",
      mdiMagnify,
      showInsertedInDoc: false,
    };
  },
};
</script>

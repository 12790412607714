var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {
      attrs: { fluid: "", height: "100vh", width: "100%", "max-width": "100%" },
    },
    [
      _c(
        "v-toolbar",
        {
          staticClass:
            "white--text elevation-0 flex-grow-0 top-bar mt-n14 mx-0 px-0",
          attrs: { height: "56px" },
        },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mx-0",
                  attrs: { color: "white" },
                  on: {
                    click: function ($event) {
                      _vm.showMenuText = !_vm.showMenuText
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.mdiMenu) + " ")]
              ),
              _c("div", { staticClass: "px-8" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.formDefinition.form.formDescription.title) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { dark: "", text: "", outlined: "" },
              on: {
                click: function ($event) {
                  _vm.showPlaceholderDrawer = true
                },
              },
            },
            [
              _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiPlus) + " ")]),
              _vm._v(" Form Placeholders "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: {
                dark: "",
                type: "submit",
                outlined: "",
                disabled: _vm.isButtonDisabled,
                id: "save-button",
              },
              on: { click: _vm.saveForm },
            },
            [_vm._v(" Save Form ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: { click: _vm.formBuilderClose },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c("section", { staticClass: "d-flex" }, [
        !_vm.showMenuText
          ? _c(
              "section",
              {
                staticClass: "white overflow-y-hidden",
                style: {
                  height: `calc(100vh - 56px)`,
                  width: `56px`,
                  "min-width": "56px",
                  "max-width": "56px",
                },
                on: {
                  mouseenter: function ($event) {
                    _vm.showMenuText = true
                  },
                },
              },
              [
                _c(
                  "v-list",
                  {
                    ref: "formBuilderMenu",
                    staticClass: "pa-0",
                    attrs: { dense: "" },
                    on: {
                      mouseenter: function ($event) {
                        _vm.showMenuText = true
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.FORM_BUILDER
                            ? "highlighted"
                            : "",
                        attrs: { id: "formBuilderTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.FORM_BUILDER
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiFormSelect)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.UTILIBOTS.DOC_BUILDER
                            ? "highlighted"
                            : "",
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.UTILIBOTS.DOC_BUILDER
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiFileDocumentEdit)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.UTILIBOTS.EMAIL
                            ? "highlighted"
                            : "",
                        attrs: { id: "emailTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.UTILIBOTS.EMAIL
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiEmail)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.selectedFormDefLayer.service_type === "F"
                      ? _c(
                          "v-list-item",
                          {
                            class:
                              _vm.activeTab ===
                              _vm.tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                                ? "highlighted"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.activeTab =
                                  _vm.tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "px-0 mx-0" },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(_vm._s(_vm.mdiTable)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isLocateRequestLayer
                      ? _c(
                          "v-list-item",
                          {
                            class:
                              _vm.activeTab ===
                              _vm.tabs.UTILIBOTS.SAVE_811_TICKET
                                ? "highlighted"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.activeTab =
                                  _vm.tabs.UTILIBOTS.SAVE_811_TICKET
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "px-0 mx-0" },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(_vm._s(_vm.mdiMapMarkerRadius)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.UTILIBOTS.SCHEDULE_FORM
                            ? "highlighted"
                            : "",
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.UTILIBOTS.SCHEDULE_FORM
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiCalendarCheck)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.hasCityworksAMSIntegration
                      ? _c(
                          "v-list-item",
                          {
                            class:
                              _vm.activeTab === _vm.tabs.UTILIBOTS.CITYWORKS
                                ? "highlighted"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.activeTab = _vm.tabs.UTILIBOTS.CITYWORKS
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "px-0 mx-0" },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(_vm._s(_vm.mdiCity)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-divider"),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.SETTINGS
                            ? "highlighted"
                            : "",
                        attrs: { id: "settingsTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.SETTINGS
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiCog)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.showMenuText
          ? _c(
              "section",
              {
                staticClass: "white overflow-y-hidden",
                style: {
                  height: `calc(100vh - 56px)`,
                  width: `56px`,
                  "min-width": "56px",
                  "max-width": "56px",
                },
                on: {
                  mouseleave: function ($event) {
                    _vm.showMenuText = false
                  },
                },
              },
              [
                _c(
                  "v-list",
                  {
                    staticClass: "pa-0 border-right",
                    style: {
                      position: "fixed",
                      top: "56px",
                      "z-index": 1000,
                      height: "calc(100vh - 56px)",
                    },
                    attrs: { dense: "" },
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.FORM_BUILDER
                            ? "highlighted"
                            : "",
                        attrs: { id: "formBuilderTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.FORM_BUILDER
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiFormSelect)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-title",
                          { staticClass: "nav-list-items pl-3" },
                          [_vm._v(" Form Builder ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.UTILIBOTS.DOC_BUILDER
                            ? "highlighted"
                            : "",
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.UTILIBOTS.DOC_BUILDER
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiFileDocumentEdit)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-title",
                          { staticClass: "nav-list-items pl-3" },
                          [_vm._v(" Doc Builder ")]
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.UTILIBOTS.EMAIL
                            ? "highlighted"
                            : "",
                        attrs: { id: "emailTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.UTILIBOTS.EMAIL
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiEmail)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-title",
                          { staticClass: "nav-list-items pl-3" },
                          [_vm._v(" Email ")]
                        ),
                      ],
                      1
                    ),
                    _vm.selectedFormDefLayer.service_type === "F"
                      ? _c(
                          "v-list-item",
                          {
                            class:
                              _vm.activeTab ===
                              _vm.tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                                ? "highlighted"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.activeTab =
                                  _vm.tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "px-0 mx-0" },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(_vm._s(_vm.mdiTable)),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-title",
                              { staticClass: "nav-list-items pl-3" },
                              [_vm._v(" ArcGIS Related Table ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isLocateRequestLayer
                      ? _c(
                          "v-list-item",
                          {
                            class:
                              _vm.activeTab ===
                              _vm.tabs.UTILIBOTS.SAVE_811_TICKET
                                ? "highlighted"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.activeTab =
                                  _vm.tabs.UTILIBOTS.SAVE_811_TICKET
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "px-0 mx-0" },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(_vm._s(_vm.mdiMapMarkerRadius)),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-title",
                              { staticClass: "nav-list-items pl-3" },
                              [_vm._v(" Save 811 Tickets to Feature Service ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.UTILIBOTS.SCHEDULE_FORM
                            ? "highlighted"
                            : "",
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.UTILIBOTS.SCHEDULE_FORM
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiCalendarCheck)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-title",
                          { staticClass: "nav-list-items pl-3" },
                          [_vm._v(" Schedule Form ")]
                        ),
                      ],
                      1
                    ),
                    _vm.hasCityworksAMSIntegration
                      ? _c(
                          "v-list-item",
                          {
                            class:
                              _vm.activeTab === _vm.tabs.UTILIBOTS.CITYWORKS
                                ? "highlighted"
                                : "",
                            on: {
                              click: function ($event) {
                                _vm.activeTab = _vm.tabs.UTILIBOTS.CITYWORKS
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "px-0 mx-0" },
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(_vm._s(_vm.mdiCity)),
                                ]),
                              ],
                              1
                            ),
                            _vm.showMenuText
                              ? _c(
                                  "v-list-item-title",
                                  { staticClass: "nav-list-items pl-3" },
                                  [_vm._v(" Cityworks ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-divider"),
                    _c(
                      "v-list-item",
                      {
                        class:
                          _vm.activeTab === _vm.tabs.SETTINGS
                            ? "highlighted"
                            : "",
                        attrs: { id: "settingsTab" },
                        on: {
                          click: function ($event) {
                            _vm.activeTab = _vm.tabs.SETTINGS
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          { staticClass: "px-0 mx-0" },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(_vm.mdiCog)),
                            ]),
                          ],
                          1
                        ),
                        _vm.showMenuText
                          ? _c(
                              "v-list-item-title",
                              { staticClass: "nav-list-items pl-3" },
                              [_vm._v(" Settings ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-divider"),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "form",
          {
            staticClass: "elevation-0 pa-0 ma-0 flex-grow-1 d-flex",
            class: { "flex-wrap": _vm.$vuetify.breakpoint.xsOnly },
            style: {
              height: _vm.$vuetify.breakpoint.smAndUp
                ? "calc(100vh - 56px)"
                : "auto",
              "min-width": "0px",
            },
          },
          [
            _c("UnsavedChangesDialog", {
              attrs: {
                showFormBuilderEditFormCloseDialog:
                  _vm.showFormBuilderEditFormCloseDialog,
              },
              on: {
                "form-builder-edit-form-save-and-close":
                  _vm.onFormBuilderEditFormSaveAndClose,
                "form-builder-edit-form-close": function ($event) {
                  return _vm.$emit("form-builder-edit-form-close")
                },
                "reverse-changes": function ($event) {
                  _vm.showFormBuilderEditFormCloseDialog = false
                },
              },
            }),
            _c(
              "section",
              {
                staticClass: "flex-grow-1 overflow-y-auto",
                class: {
                  "main-page-background": [
                    _vm.tabs.SETTINGS,
                    _vm.tabs.FORM_BUILDER,
                  ].includes(_vm.activeTab),
                  "gray-main-page-background": ![
                    _vm.tabs.SETTINGS,
                    _vm.tabs.FORM_BUILDER,
                  ].includes(_vm.activeTab),
                },
              },
              [
                _vm.activeTab === _vm.tabs.SETTINGS
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("SettingsForm", {
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.FORM_BUILDER
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "mt-6 mb-3 mx-auto",
                          attrs: { id: "preview-form" },
                        },
                        [
                          _c("v-switch", {
                            attrs: { label: "Preview Form" },
                            on: { change: _vm.onPreviewModeToggle },
                            model: {
                              value: _vm.previewMode,
                              callback: function ($$v) {
                                _vm.previewMode = $$v
                              },
                              expression: "previewMode",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showAdvancedFormEditDialog
                        ? _c("AdvancedFormEditDialog", {
                            attrs: {
                              showAdvancedFormEditDialog:
                                _vm.showAdvancedFormEditDialog,
                            },
                            on: {
                              "close-advanced-form-edit-dialog": function (
                                $event
                              ) {
                                _vm.showAdvancedFormEditDialog = false
                              },
                              input: function ($event) {
                                _vm.showAdvancedFormEditDialog = false
                              },
                            },
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto elevation-0",
                          attrs: {
                            width: "600px",
                            "min-width": "600px",
                            "max-width": "600px",
                            id: "formBuilder",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formDefinition.form.formDescription
                                        .title
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      icon: "",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiDotsVertical
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3221288465
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.showAdvancedFormEditDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { color: "primary" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.mdiPencil) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "overflowText",
                                              attrs: { color: "primary" },
                                            },
                                            [_vm._v(" Advanced Edit ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "elevation-0 ma-0 pa-0" },
                            [
                              _c(
                                "validation-observer",
                                { ref: "formPreview", attrs: { slim: "" } },
                                [
                                  _c(
                                    "form",
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        {
                                          attrs: { multiple: "" },
                                          model: {
                                            value: _vm.openSections,
                                            callback: function ($$v) {
                                              _vm.openSections = $$v
                                            },
                                            expression: "openSections",
                                          },
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              staticClass: "list-group",
                                              attrs: {
                                                list: _vm.formDefinition.form
                                                  .sections,
                                                group: "sections",
                                                options: {
                                                  disabled: _vm.previewMode,
                                                },
                                                handle: ".section-handle",
                                              },
                                              on: {
                                                end: _vm.assignNumbersToSections,
                                              },
                                            },
                                            _vm._l(
                                              _vm.formDefinition.form.sections,
                                              function (section, sectionIndex) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.isSectionVisible(
                                                            section
                                                          ),
                                                        expression:
                                                          "isSectionVisible(section)",
                                                      },
                                                    ],
                                                    key: section.id,
                                                  },
                                                  [
                                                    _c(
                                                      "v-expansion-panel-header",
                                                      {
                                                        class: {
                                                          selectedField:
                                                            _vm.selectedWholeSectionId ===
                                                            section.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.selectItem(
                                                              section.id,
                                                              undefined,
                                                              "SECTION"
                                                            )
                                                            _vm.selectWholeSection(
                                                              section
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center",
                                                          },
                                                          [
                                                            !_vm.previewMode
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "section-handle",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.mdiDragVertical
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "px-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      section.hideHeader
                                                                        ? ""
                                                                        : section
                                                                        ? section.name
                                                                        : ""
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-spacer"),
                                                        !_vm.previewMode &&
                                                        _vm.selectedWholeSectionId ===
                                                          section.id
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.showConfirmDeleteSectionDialog = true
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.mdiDelete
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Delete "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.duplicateSection(
                                                                            sectionIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.mdiContentCopy
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Duplicate "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "ConfirmDeleteDialog",
                                                                  {
                                                                    attrs: {
                                                                      itemType:
                                                                        "section",
                                                                      showConfirmDialog:
                                                                        _vm.showConfirmDeleteSectionDialog,
                                                                    },
                                                                    on: {
                                                                      "confirm-delete":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.deleteSection(
                                                                            sectionIndex
                                                                          )
                                                                          _vm.showConfirmDeleteSectionDialog = false
                                                                        },
                                                                      "cancel-delete":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.showConfirmDeleteSectionDialog = false
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      {
                                                        class: {
                                                          selectedField:
                                                            _vm.selectedWholeSectionId ===
                                                            section.id,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "draggable",
                                                          {
                                                            staticClass:
                                                              "list-group",
                                                            attrs: {
                                                              list: section.items,
                                                              group: "items",
                                                              options: {
                                                                disabled:
                                                                  _vm.previewMode,
                                                              },
                                                              handle: ".handle",
                                                            },
                                                            on: {
                                                              end: _vm.assignNumbersToItems,
                                                            },
                                                          },
                                                          _vm._l(
                                                            section.items,
                                                            function (
                                                              item,
                                                              itemIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: `${item.id}-${item.number}`,
                                                                  staticClass:
                                                                    "elevation-0 py-0",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.selectItem(
                                                                          section.id,
                                                                          item.id,
                                                                          _vm.getSelectedItemType(
                                                                            item
                                                                          )
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: {
                                                                        selectedField:
                                                                          _vm.selectedItemId ===
                                                                            item.id ||
                                                                          _vm.selectedWholeSectionId ===
                                                                            section.id,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "section",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex px-0 pb-0 pt-2 justify-space-between align-center",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "ItemPreview",
                                                                            {
                                                                              staticClass:
                                                                                "flex-grow-1 my-4",
                                                                              attrs:
                                                                                {
                                                                                  sectionId:
                                                                                    section.id,
                                                                                  item: item,
                                                                                  formDefinition:
                                                                                    _vm.formDefinition,
                                                                                  selected:
                                                                                    _vm.selectedItemId ===
                                                                                      item.id ||
                                                                                    _vm.selectedWholeSectionId ===
                                                                                      section.id,
                                                                                  previewMode:
                                                                                    _vm.previewMode,
                                                                                  readOnly:
                                                                                    !_vm.previewMode,
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  _vm.onInput,
                                                                              },
                                                                            }
                                                                          ),
                                                                          _vm.selectedItemId ===
                                                                            item.id &&
                                                                          !_vm.previewMode
                                                                            ? _c(
                                                                                "section",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex flex-grow-0",
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.selectItem(
                                                                                          section.id,
                                                                                          item.id,
                                                                                          _vm.getSelectedItemType(
                                                                                            item
                                                                                          )
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex flex-column flex-align-center",
                                                                                    },
                                                                                    [
                                                                                      _vm.showDuplicateButton(
                                                                                        item
                                                                                      )
                                                                                        ? _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mb-2",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  text: "",
                                                                                                  width:
                                                                                                    "20px",
                                                                                                  "min-width":
                                                                                                    "20px",
                                                                                                  height:
                                                                                                    "20px",
                                                                                                  "min-height":
                                                                                                    "20px",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    return _vm.duplicateItem(
                                                                                                      section.id,
                                                                                                      item.id
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.mdiContentCopy
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      item.type ===
                                                                                      "QUESTION"
                                                                                        ? _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mb-2",
                                                                                              attrs:
                                                                                                {
                                                                                                  color:
                                                                                                    "primary",
                                                                                                  text: "",
                                                                                                  width:
                                                                                                    "20px",
                                                                                                  "min-width":
                                                                                                    "20px",
                                                                                                  height:
                                                                                                    "20px",
                                                                                                  "min-height":
                                                                                                    "20px",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.showAdvancedEditDialog = true
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-2",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.mdiPencil
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-2",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "primary",
                                                                                              text: "",
                                                                                              width:
                                                                                                "20px",
                                                                                              "min-width":
                                                                                                "20px",
                                                                                              height:
                                                                                                "20px",
                                                                                              "min-height":
                                                                                                "20px",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.showConfirmDeleteDialog = true
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mr-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.mdiDelete
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm.showConfirmDeleteDialog
                                                                                    ? _c(
                                                                                        "ConfirmDeleteDialog",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              itemType:
                                                                                                "item",
                                                                                              showConfirmDialog:
                                                                                                _vm.showConfirmDeleteDialog,
                                                                                            },
                                                                                          on: {
                                                                                            "confirm-delete":
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.removeItemFromSection(
                                                                                                  section.id,
                                                                                                  item.id
                                                                                                )
                                                                                                _vm.showConfirmDeleteDialog = false
                                                                                              },
                                                                                            "cancel-delete":
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.showConfirmDeleteDialog = false
                                                                                              },
                                                                                          },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm.checkFieldIsNotLast(
                                                                    item,
                                                                    section.id
                                                                  )
                                                                    ? _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "pa-0",
                                                                            },
                                                                            [
                                                                              _vm.selectedItemId ===
                                                                                item.id &&
                                                                              !_vm.previewMode
                                                                                ? _c(
                                                                                    "section",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex mt-4 mb-0 px-5 justify-center",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              dark: "",
                                                                                              color:
                                                                                                "primary",
                                                                                              tile: "",
                                                                                              disabled:
                                                                                                _vm.addingField,
                                                                                              id: "inlineAddFieldBtn",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                _vm.selectedSectionId =
                                                                                                  section.id
                                                                                                _vm.addAtIndex =
                                                                                                  itemIndex
                                                                                                _vm.showFields = true
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.mdiPlus
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pl-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "ADD FIELD"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        !_vm.previewMode
                                                          ? _c(
                                                              "section",
                                                              {
                                                                staticClass:
                                                                  "d-flex my-5 px-5 justify-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      dark: "",
                                                                      color:
                                                                        "primary",
                                                                      tile: "",
                                                                      id: "bottomAddFieldBtn",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          _vm.selectedSectionId =
                                                                            section.id
                                                                          _vm.showFields = true
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.mdiPlus
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pl-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "ADD FIELD"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.previewMode
                        ? _c(
                            "v-card",
                            {
                              staticClass: "pl-5 pr-5 mx-auto",
                              attrs: {
                                width: "600px",
                                "min-width": "600px",
                                "max-width": "600px",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        dark: "",
                                        tile: "",
                                        id: "addSectionBtn",
                                      },
                                      on: { click: _vm.addSection },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.mdiPlus)),
                                      ]),
                                      _c("div", { staticClass: "pl-2" }, [
                                        _vm._v("ADD SECTION"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.UTILIBOTS.DOC_BUILDER
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("ReportTemplateForm", {
                            attrs: {
                              activeTab: _vm.activeTab,
                              htmlVersion:
                                _vm.formDefinition
                                  .html_merge_definition_version,
                              selectedFormDefLayer: _vm.selectedFormDefLayer,
                              isLocateRequestLayer: _vm.isLocateRequestLayer,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onTemplateChanged()
                              },
                            },
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.UTILIBOTS.EMAIL
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("EmailForm", {
                            attrs: { id: "emailForm" },
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.UTILIBOTS.ARCGIS_RELATED_TABLE
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("ArcGisRelatedTableForm", {
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.UTILIBOTS.SAVE_811_TICKET
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("Save811TicketForm", {
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.UTILIBOTS.SCHEDULE_FORM
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("TaskSchedulerForm", {
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.activeTab === _vm.tabs.UTILIBOTS.CITYWORKS
                  ? [
                      _c(
                        "validation-observer",
                        { ref: "settingsForm" },
                        [
                          _c("CityworksForm", {
                            model: {
                              value: _vm.formDefinition,
                              callback: function ($$v) {
                                _vm.formDefinition = $$v
                              },
                              expression: "formDefinition",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "v-navigation-drawer",
              {
                staticStyle: { "z-index": "1002" },
                attrs: {
                  stateless: "",
                  app: "",
                  left: "",
                  permanent: _vm.showFields,
                  id: "fieldSelectionPanel",
                },
                model: {
                  value: _vm.showFields,
                  callback: function ($$v) {
                    _vm.showFields = $$v
                  },
                  expression: "showFields",
                },
              },
              [
                [
                  _c(
                    "v-toolbar",
                    {
                      ref: "toolbar",
                      staticClass: "elevation-0",
                      attrs: { color: "primary", height: "56px" },
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "white--text text-h6 ml-2" },
                        [_vm._v(" Select Field ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "white" },
                          on: {
                            click: function ($event) {
                              _vm.showFields = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    {
                      staticClass: "overflow-y-auto",
                      staticStyle: { "max-height": "calc(100vh - 56px)" },
                      attrs: { dense: "" },
                    },
                    [
                      _c("v-subheader", [_vm._v("STANDARD QUESTIONS")]),
                      _vm._l(_vm.standardQuestionTypes, function (q) {
                        return _c(
                          "v-list-item",
                          {
                            key: q.title,
                            on: {
                              click: function ($event) {
                                _vm.showFields = false
                                _vm.selectItemType(q.title)
                                _vm.addItemToForm(
                                  _vm.selectedQuestionType,
                                  _vm.selectedSectionId,
                                  _vm.addAtIndex
                                )
                                _vm.selectItem(
                                  _vm.selectedSectionId,
                                  _vm.selectedItemId,
                                  _vm.getSelectedItemType(_vm.selectedItem)
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(q.icon)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pl-3",
                                staticStyle: { color: "#093637" },
                              },
                              [_vm._v(_vm._s(q.title))]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("v-subheader", [_vm._v("ADVANCED QUESTIONS")]),
                      _vm._l(_vm.computedAdvancedQuestionTypes, function (aq) {
                        return _c(
                          "v-list-item",
                          {
                            key: aq.title,
                            on: {
                              click: function ($event) {
                                _vm.showFields = false
                                _vm.addingField = true
                                _vm.selectItemType(aq.title)
                                _vm.addItemToForm(
                                  _vm.selectedQuestionType,
                                  _vm.selectedSectionId,
                                  _vm.addAtIndex
                                )
                                _vm.addingField = false

                                _vm.selectItem(
                                  _vm.selectedSectionId,
                                  _vm.selectedItemId,
                                  _vm.getSelectedItemType(_vm.selectedItem)
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(aq.icon)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pl-3",
                                staticStyle: { color: "#093637" },
                              },
                              [_vm._v(_vm._s(aq.title))]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("v-subheader", [_vm._v("INFORMATION TYPE")]),
                      _vm._l(_vm.itemTypes, function (it) {
                        return _c(
                          "v-list-item",
                          {
                            key: it.title,
                            on: {
                              click: function ($event) {
                                _vm.showFields = false

                                _vm.selectItemType(it.title)
                                _vm.addItemToForm(
                                  _vm.selectedQuestionType,
                                  _vm.selectedSectionId,
                                  _vm.addAtIndex
                                )
                                _vm.selectItem(
                                  _vm.selectedSectionId,
                                  _vm.selectedItemId,
                                  _vm.getSelectedItemType(_vm.selectedItem)
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v(_vm._s(it.icon)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pl-3",
                                staticStyle: { color: "#093637" },
                              },
                              [_vm._v(_vm._s(it.title))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
              ],
              2
            ),
            _c(
              "v-navigation-drawer",
              {
                staticStyle: { "z-index": "1002" },
                attrs: {
                  stateless: "",
                  app: "",
                  right: "",
                  permanent:
                    typeof _vm.selectedItemId === "string" && _vm.showDrawer,
                },
                model: {
                  value: _vm.showDrawer,
                  callback: function ($$v) {
                    _vm.showDrawer = $$v
                  },
                  expression: "showDrawer",
                },
              },
              [
                _vm.showDrawer
                  ? _c("FormControlEditForm", {
                      attrs: {
                        selectedSectionId: _vm.selectedSectionId,
                        selectedItemId: _vm.selectedItemId,
                        selectedControlType: _vm.selectedControlType,
                        edit: typeof _vm.selectedFormDefinitionId === "string",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.assignNumbersToItems()
                        },
                        "discard-changes": function ($event) {
                          _vm.showDrawer = false
                        },
                        "form-control-form-submitted": function ($event) {
                          _vm.showDrawer = false
                        },
                      },
                      model: {
                        value: _vm.formDefinition,
                        callback: function ($$v) {
                          _vm.formDefinition = $$v
                        },
                        expression: "formDefinition",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-navigation-drawer",
              {
                staticStyle: { "z-index": "1002" },
                attrs: {
                  stateless: "",
                  app: "",
                  right: "",
                  permanent: _vm.showPlaceholderDrawer,
                  width: "300px",
                },
                model: {
                  value: _vm.showPlaceholderDrawer,
                  callback: function ($$v) {
                    _vm.showPlaceholderDrawer = $$v
                  },
                  expression: "showPlaceholderDrawer",
                },
              },
              [
                _c(
                  "v-toolbar",
                  { attrs: { color: "primary", height: "56px" } },
                  [
                    _c(
                      "v-toolbar-title",
                      { staticClass: "white--text text-h6 ml-2" },
                      [_vm._v(" Form Placeholders ")]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white" },
                        on: {
                          click: function ($event) {
                            _vm.showPlaceholderDrawer = false
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("FormPlaceholdersForm", {
                  attrs: {
                    formDefinition: _vm.formDefinition,
                    type: _vm.activeTab,
                  },
                  on: {
                    "placeholder-copied": function ($event) {
                      _vm.showCopiedSnackbar = true
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "1001" },
          attrs: { timeout: 3000 },
          model: {
            value: _vm.showSavedSnackbar,
            callback: function ($$v) {
              _vm.showSavedSnackbar = $$v
            },
            expression: "showSavedSnackbar",
          },
        },
        [_vm._v(" Form Saved ")]
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "1001" },
          model: {
            value: _vm.showCopiedSnackbar,
            callback: function ($$v) {
              _vm.showCopiedSnackbar = $$v
            },
            expression: "showCopiedSnackbar",
          },
        },
        [_vm._v(" Form Placeholder Copied ")]
      ),
      _c(
        "v-dialog",
        {
          style: { height: _vm.$vuetify.breakpoint.xsOnly ? "100vh" : "95vh" },
          attrs: {
            "max-width": _vm.$vuetify.breakpoint.xsOnly ? undefined : "600px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            persistent: "",
          },
          model: {
            value: _vm.showAdvancedEditDialog,
            callback: function ($$v) {
              _vm.showAdvancedEditDialog = $$v
            },
            expression: "showAdvancedEditDialog",
          },
        },
        [
          _vm.showAdvancedEditDialog
            ? _c("AdvancedEditForm", {
                attrs: {
                  selectedSectionId: _vm.selectedSectionId,
                  selectedItemId: _vm.selectedItemId,
                  selectedControlType: _vm.selectedControlType,
                },
                on: {
                  "close-advanced-edit-dialog": function ($event) {
                    _vm.showAdvancedEditDialog = false
                  },
                  input: function ($event) {
                    _vm.showAdvancedEditDialog = false
                    _vm.assignNumbersToItems()
                    _vm.reloadDrawer()
                  },
                },
                model: {
                  value: _vm.formDefinition,
                  callback: function ($$v) {
                    _vm.formDefinition = $$v
                  },
                  expression: "formDefinition",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
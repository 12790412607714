var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item",
            { staticClass: "pr-0 mr-0 my-0 py-0" },
            [
              _c(
                "v-list-item-content",
                [
                  _c("validation-provider", {
                    attrs: { name: "Dependant", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.dependantOptions,
                                label: "Dependant",
                                "error-messages": errors,
                                success: valid,
                                "item-text": "label",
                                "item-value": "value",
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$emit(
                                    "input",
                                    _vm.requiredVisibleOptions
                                  )
                                },
                              },
                              model: {
                                value: _vm.requiredVisibleOptions.dependantId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.requiredVisibleOptions,
                                    "dependantId",
                                    $$v
                                  )
                                },
                                expression:
                                  "requiredVisibleOptions.dependantId",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.groupItemDependantOptions.length > 0
            ? _c(
                "v-list-item",
                { staticClass: "pr-0 mr-0 my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: {
                          name: "Dependant Group Item",
                          rules: {
                            required: _vm.groupItemDependantOptions.length > 0,
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-autocomplete", {
                                    staticStyle: { "max-width": "168px" },
                                    attrs: {
                                      items: _vm.groupItemDependantOptions,
                                      label: "Dependant Group Item",
                                      "error-messages": errors,
                                      success: valid,
                                      "item-text": "label",
                                      "item-value": "value",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$emit(
                                          "input",
                                          _vm.requiredVisibleOptions
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.requiredVisibleOptions
                                          .dependantGroupItemId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.requiredVisibleOptions,
                                          "dependantGroupItemId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "requiredVisibleOptions.dependantGroupItemId",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          941977415
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-item",
            { staticClass: "pr-0 mr-0 my-0 py-0" },
            [
              _c(
                "v-list-item-content",
                [
                  _c("validation-provider", {
                    attrs: { name: "Comparison", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.comparisonOptions,
                                label: "Comparison",
                                "error-messages": errors,
                                success: valid,
                                "item-text": "label",
                                "item-value": "value",
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$emit(
                                    "input",
                                    _vm.requiredVisibleOptions
                                  )
                                },
                              },
                              model: {
                                value: _vm.requiredVisibleOptions.condition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.requiredVisibleOptions,
                                    "condition",
                                    $$v
                                  )
                                },
                                expression: "requiredVisibleOptions.condition",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "pr-0 mr-0" },
            [
              _c(
                "v-list-item-content",
                [
                  _c("validation-provider", {
                    attrs: { name: "Value", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _vm.isSelectQuestion
                              ? [
                                  _vm.isImageSelectQuestion
                                    ? [
                                        _c(
                                          "v-radio-group",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.$emit(
                                                  "input",
                                                  _vm.requiredVisibleOptions
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.requiredVisibleOptions
                                                  .value,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.requiredVisibleOptions,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "requiredVisibleOptions.value",
                                            },
                                          },
                                          _vm._l(
                                            _vm.comparisonValues,
                                            function (option) {
                                              return _c("v-radio", {
                                                key: option.value,
                                                attrs: { value: option.value },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c("img", {
                                                            attrs: {
                                                              src: option.value,
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.comparisonValues,
                                          label: "Value",
                                          "error-messages": errors,
                                          success: valid,
                                          "item-text": "label",
                                          "item-value": "value",
                                          "hide-details": "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$emit(
                                              "input",
                                              _vm.requiredVisibleOptions
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.requiredVisibleOptions.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.requiredVisibleOptions,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requiredVisibleOptions.value",
                                        },
                                      }),
                                ]
                              : _c("v-text-field", {
                                  attrs: {
                                    label: "Value",
                                    "hide-details": "auto",
                                    "error-messages": errors,
                                    success: valid,
                                    color: "primary",
                                    name: "value",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.requiredVisibleOptions
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.requiredVisibleOptions.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.requiredVisibleOptions,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression: "requiredVisibleOptions.value",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="d-flex justify-space-between">
    <validation-observer ref="photoForm">
      <form>
        <v-list
          v-if="
            selectedRepeatingGroupItemOptions &&
            selectedRepeatingGroupItemOptions.question
          "
        >
          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center justify-space-between">
                    <h3 class="font-weight-regular">
                      {{
                        selectedRepeatingGroupItemOptions.question.type
                          | itemTitle(selectedRepeatingGroupItemOptions)
                      }}
                    </h3>

                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>{{ mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </div>
                </template>

                <v-list>
                  <v-list-item
                    @click="
                      removeRepeatingGroupItem(
                        selectedRepeatingGroupItemOptions.id
                      )
                    "
                  >
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Label"
                rules="required"
              >
                <v-text-field
                  v-model="selectedRepeatingGroupItemOptions.question.label"
                  label="Label"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  @change="onSubmit()"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Required"
                rules="required"
              >
                <v-select
                  v-model="
                    selectedRepeatingGroupItemOptions.question.required
                      .condition
                  "
                  :items="groupItemRequiredOptions"
                  label="Required"
                  :error-messages="errors"
                  :success="valid"
                  item-text="label"
                  item-value="value"
                  @change="onSubmit()"
                ></v-select>
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="selectedRepeatingGroupItemOptions.question.isImage"
            class="pa-0 ma-0"
          >
            <v-list-item-content>
              <validation-provider
                v-slot="{ errors, valid }"
                name="Maximum Width/Height of Photo"
                rules="min_value:0"
              >
                <v-text-field
                  v-model="
                    selectedRepeatingGroupItemOptions.question.maxWidthHeight
                  "
                  label="Maximum Width/Height of Photo"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  @change="onSubmit()"
                />
              </validation-provider>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
              <v-switch
                v-model="
                  selectedRepeatingGroupItemOptions.question.showDescription
                "
                label="Show Description"
                @change="onSubmit()"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pa-0 ma-0">
            <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
              <v-switch
                v-model="selectedRepeatingGroupItemOptions.allowMultiple"
                label="Allow Multiple"
                @change="onSubmit()"
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {
  mdiDragVertical,
  mdiDotsVertical,
  mdiDelete,
  mdiPencil,
} from "@mdi/js";
import { cloneDeep } from "lodash";
import { groupItemRequiredOptions } from "@/constants/choices";

export default {
  name: "PhotoForm",
  data() {
    return {
      mdiDragVertical,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedRepeatingGroupItemOptions: {},
      groupItemRequiredOptions,
    };
  },
  props: { value: Object },
  beforeMount() {
    this.selectedRepeatingGroupItemOptions = cloneDeep(this.value);
    if (!this.selectedRepeatingGroupItemOptions?.question?.required) {
      this.selectedRepeatingGroupItemOptions.question.required = {
        condition: "NEVER",
      };
    }

    if (!this.selectedRepeatingGroupItemOptions?.question?.default) {
      this.selectedRepeatingGroupItemOptions.question.default = {};
    }
  },
  methods: {
    async onSubmit() {
      const success = await this.$refs.photoForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedRepeatingGroupItemOptions);
    },
    removeRepeatingGroupItem(selectedRepeatingGroupItemOptionsId) {
      this.$emit(
        "remove-repeating-group-item",
        selectedRepeatingGroupItemOptionsId
      );
    },
  },
};
</script>

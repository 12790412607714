<template>
  <v-card class="elevation-2">
    <v-card-text class="px-6 pt-0">
      <v-row>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Observations">
            <v-text-field
              label="Observations"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.ObservationSum
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Repairs">
            <v-text-field
              label="Repairs"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.RepairsMade
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row v-if="activityType === 'workorder'">
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Work Order Comments"
          >
            <v-text-field
              label="Comments"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .work_order_comment
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Work Order Instructions"
          >
            <v-text-field
              label="Instructions"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .work_order_instructions
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row v-else-if="activityType === 'Inspection'">
        <v-col cols="12">
          <validation-provider v-slot="{ errors, valid }" name="Recommendation">
            <v-text-field
              label="Recommendation"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.ForemanRecomnd
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Condition Rating"
          >
            <v-text-field
              label="Condition Rating"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="value"
              v-model="
                formDefinition.form.city_works_webhook_config.on_form_submission
                  .inspection_comments.CondRating
              "
              @input="onInput"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";

export default {
  name: "CityworksCommentsForm",
  props: {
    value: Object,
    activityType: String,
  },
  mixins: [questionsMixin],
  data() {
    return {
      formDefinition: {},
    };
  },
  methods: {
    onInput() {
      this.$emit("input", this.formDefinition);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (
          !this.formDefinition.form.city_works_webhook_config.on_form_submission
            .inspection_comments
        ) {
          this.formDefinition.form.city_works_webhook_config.on_form_submission.inspection_comments =
            {
              ForemanRecomnd: null,
              ObservationSum: null,
              RepairsMade: null,
              CondRating: null,
            };
        }
      },
    },
  },
};
</script>

<template>
  <validation-observer ref="actionItemInputForm">
    <v-toolbar color="primary" height="56px" class="elevation-0" ref="toolbar">
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <div class="d-flex justify-space-between">
            <v-list-item-title class="white--text text-h6">
              {{ selectedControlType | itemTitle(selectedItem) }}
            </v-list-item-title>
            <v-btn
              icon
              color="white"
              @click="$emit('form-control-form-submitted')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-toolbar>
    <form
      @submit.prevent="
        onSubmit();
        $emit('form-control-form-submitted');
      "
    >
      <v-list
        dense
        style="max-height: calc(100vh - 56px)"
        class="overflow-y-auto"
      >
        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Label"
              rules="required"
            >
              <v-text-field
                v-model="selectedItemOptions.question.label"
                label="Label"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="label"
                @change="onSubmit()"
                ref="labelInput"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Hint">
              <v-text-field
                v-model="selectedItemOptions.question.descriptionHint"
                label="Hint"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="hint"
                @change="onSubmit()"
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider
              v-slot="{ errors, valid }"
              name="Assignee Email Fields"
            >
              <v-select
                multiple
                v-model="selectedItemOptions.question.populateEmailWithIds"
                label="Assignee Email Fields"
                :items="assigneeEmailFieldChoices"
                item-text="label"
                item-value="value"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="hint"
                @change="onSubmit()"
                hide-details
              />
            </validation-provider>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <validation-provider v-slot="{ errors, valid }" name="Visible">
              <v-select
                v-model="createCondition"
                :items="createOptions"
                label="Create"
                :error-messages="errors"
                :success="valid"
                item-text="label"
                item-value="value"
                @change="onSubmit()"
                hide-details
              >
              </v-select>
            </validation-provider>

            <CreateComparisonConditionForm
              v-if="['conditional'].includes(createCondition)"
              v-model="selectedItemOptions.question.create"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <AdvancedSettingsForm
              v-model="selectedItemOptions"
              :formDefinition="formDefinition"
              :selectedItem="selectedItem"
              :layer="layer"
              @input="onSubmit()"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <div class="d-flex flex-wrap gap" v-if="hasAdvancedSettings">
              <section
                class="text-uppercase d-flex primary--text gap align-center"
                v-if="hasVisibleSettings"
                @click="showAdvancedVisibilitySettingsDialog = true"
              >
                <div>Visible {{ visibleSetting }}</div>

                <v-btn icon>
                  <v-icon color="primary">
                    {{ mdiPencil }}
                  </v-icon>
                </v-btn>
              </section>
            </div>
            <div v-else class="d-flex align-center justify-center">
              No advanced settings
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="my-0 py-0">
          <v-list-item-content>
            <v-btn color="primary" type="submit" width="100%">
              Update Item
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <AdvancedVisibilitySettingsDialog
        v-if="showAdvancedVisibilitySettingsDialog"
        :showAdvancedVisibilitySettingsDialog="
          showAdvancedVisibilitySettingsDialog
        "
        :formDefinition="formDefinition"
        :layer="layer"
        :selectedItem="selectedItem"
        @advanced-settings-dialog-close="
          showAdvancedVisibilitySettingsDialog = false
        "
        v-model="selectedItemOptions"
        @input="onSubmit()"
      />
    </form>
  </validation-observer>
</template>

<script>
import { cloneDeep } from "lodash";
import { visibilityOptions, createOptions } from "@/constants/choices";
import CreateComparisonConditionForm from "./shared/CreateComparisonConditionForm";
import { mdiChevronDown } from "@mdi/js";
import sleep from "@/mixins/sleep";
import AdvancedSettingsForm from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/AdvancedSettingsForm.vue";
import AdvancedVisibilitySettingsDialog from "@/components/forms/form-builder-edit-form/form-control-edit-form/shared/advanced-settings-form/AdvancedVisibilitySettingsDialog.vue";
import { mdiPencil } from "@mdi/js";
import { CONDITION_CHOICES } from "@/constants/advancedSettings";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "RepeatingGroupForm",
  props: {
    selectedItem: Object,
    formDefinition: Object,
    edit: Boolean,
    selectedControlType: String,
  },
  components: {
    AdvancedSettingsForm,
    AdvancedVisibilitySettingsDialog,
    CreateComparisonConditionForm,
  },
  computed: {
    assigneeEmailFieldChoices() {
      return this.formDefinition.form.sections
        .map((s) => {
          return [
            {
              header: s.name,
            },
            ...s.items
              .filter((it) => it?.question?.type === "EMAIL")
              .map((it) => {
                const {
                  id,
                  number,
                  question: { label },
                } = it;
                return { label: `${number} ${label}`, value: id };
              }),
          ];
        })
        .flat();
    },
    visibleSetting() {
      if (
        this.selectedItemOptions?.question?.visible?.applyVisible === "ALWAYS"
      ) {
        return "";
      }
      return this.selectedItemOptions?.question?.visible?.applyVisible;
    },
    hasAdvancedSettings() {
      const { hasVisibleSettings } = this;
      return hasVisibleSettings;
    },
    hasVisibleSettings() {
      return (
        this.selectedItemOptions.question.visible?.applyVisible !==
        CONDITION_CHOICES.ALWAYS
      );
    },
  },
  beforeMount() {
    this.getSelectedItemOptions();
    this.getLayer();
  },
  async mounted() {
    if (!this.selectedItemOptions.question.label) {
      await this.$nextTick();
      await sleep(500);
      this.$refs.labelInput.$refs?.input?.focus();
    }
  },
  data() {
    return {
      selectedItemOptions: {
        question: {},
      },
      createCondition: "ALWAYS",
      visibilityOptions,
      createOptions,
      mdiChevronDown,
      showAdvancedVisibilitySettingsDialog: false,
      mdiPencil,
      layer: {},
    };
  },
  methods: {
    async getLayer() {
      const { map_service_id: mapServiceId } = this.formDefinition;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      this.layer = results;
    },
    async onSubmit() {
      const success = await this.$refs.actionItemInputForm.validate();
      if (!success) {
        return;
      }
      this.$emit("input", this.selectedItemOptions);
    },
    getSelectedItemOptions() {
      this.selectedItemOptions = cloneDeep(this.selectedItem);

      if (
        this.selectedItemOptions?.question?.create &&
        !["ALWAYS"].includes(
          this.selectedItemOptions?.question?.create?.condition
        )
      ) {
        this.createCondition = "conditional";
      } else {
        this.createCondition =
          this.selectedItemOptions?.question?.create?.condition;
      }
    },
  },
  watch: {
    createCondition(val) {
      if (["ALWAYS"].includes(val)) {
        this.selectedItemOptions.question.create.condition = val;
        this.selectedItemOptions.question.create.dependent = undefined;
        this.selectedItemOptions.question.create.dependentFieldId = undefined;
        this.selectedItemOptions.question.create.value = undefined;
      }
    },
  },
};
</script>

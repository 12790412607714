var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mappingsAvailable
    ? _c("v-simple-table", {
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function () {
                return [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v("ArcGIS Feature Service Field"),
                      ]),
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v("UtiliSync Form Field"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.formDefinition.form.locate_request_settings.mappings,
                      function (mapping) {
                        return _c("tr", { key: mapping.name }, [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getAliasFromName(mapping.esri_field_name)
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c("validation-provider", {
                                attrs: { name: "UtiliSync Form Field" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors, valid }) {
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              label: "UtiliSync Form Field",
                                              "hide-details": "auto",
                                              "error-messages": errors,
                                              success: valid,
                                              color: "primary",
                                              name: "utilitySyncFormField",
                                              items:
                                                _vm.filterQuestionChoices(
                                                  mapping
                                                ),
                                              "item-text": "label",
                                              "item-value": "value",
                                            },
                                            on: { change: _vm.onChange },
                                            model: {
                                              value:
                                                mapping.utiliSync_question_id,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  mapping,
                                                  "utiliSync_question_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mapping.utiliSync_question_id",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ])
                      }
                    ),
                    0
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1534455085
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "elevation-0 mx-auto mt-4",
      attrs: { "max-width": "600", id: "settingsPanel" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "elevation-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { slim: "", name: "Title", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: "Title",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$emit("input", _vm.formDefinition)
                                },
                              },
                              model: {
                                value:
                                  _vm.formDefinition.form.formDescription.title,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formDefinition.form.formDescription,
                                    "title",
                                    $$v
                                  )
                                },
                                expression:
                                  "formDefinition.form.formDescription.title",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { slim: "", name: "Title" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: "Description",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$emit("input", _vm.formDefinition)
                                },
                              },
                              model: {
                                value: _vm.formDefinition.form.description,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formDefinition.form,
                                    "description",
                                    $$v
                                  )
                                },
                                expression: "formDefinition.form.description",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("validation-provider", {
                    attrs: { slim: "", name: "Folder" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors, valid }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                label: "Folder",
                                "hide-details": "auto",
                                "error-messages": errors,
                                success: valid,
                                color: "primary",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$emit("input", _vm.formDefinition)
                                },
                              },
                              model: {
                                value: _vm.formDefinition.folder,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formDefinition, "folder", $$v)
                                },
                                expression: "formDefinition.folder",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { label: "Editable after submitted final" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.formDescription
                          .canEditAfterFinal,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.formDescription,
                          "canEditAfterFinal",
                          $$v
                        )
                      },
                      expression:
                        "formDefinition.form.formDescription.canEditAfterFinal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { label: "Display as accordion" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.formDescription
                          .isAccordionDisplay,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.formDescription,
                          "isAccordionDisplay",
                          $$v
                        )
                      },
                      expression:
                        "formDefinition.form.formDescription.isAccordionDisplay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { label: "Show ObjectID Form Submission Page" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.formDescription.includeObjectId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.formDescription,
                          "includeObjectId",
                          $$v
                        )
                      },
                      expression:
                        "formDefinition.form.formDescription.includeObjectId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { label: "Show UtiliBots on Form Submission" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value:
                        _vm.formDefinition.form.formDescription
                          .showUtiliBotsOnFormSubmission,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition.form.formDescription,
                          "showUtiliBotsOnFormSubmission",
                          $$v
                        )
                      },
                      expression:
                        "\n            formDefinition.form.formDescription.showUtiliBotsOnFormSubmission\n          ",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { label: "Organization User Enabled" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value: _vm.formDefinition.organization_user_enabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition,
                          "organization_user_enabled",
                          $$v
                        )
                      },
                      expression: "formDefinition.organization_user_enabled",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: { label: "Site Contact Enabled" },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("input", _vm.formDefinition)
                      },
                    },
                    model: {
                      value: _vm.formDefinition.site_contact_enabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formDefinition,
                          "site_contact_enabled",
                          $$v
                        )
                      },
                      expression: "formDefinition.site_contact_enabled",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-row>
    <template v-if="item.question.isImage">
      <v-col cols="12">
        <p class="caption">
          {{ item.question.label }}
        </p>
        <v-radio-group v-model="selectedValue" :disabled="readOnly">
          <v-radio
            v-for="option in options"
            :key="option.id"
            :value="option.value"
            :readonly="readOnly"
          >
            <template v-slot:label>
              <img :src="option.value" />
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </template>
    <template v-else>
      <template v-if="item.question.showAllOptions">
        <v-col cols="12">
          <p class="caption">
            {{ item.question.label }}
          </p>
          <v-radio-group
            v-model="selectedValue"
            :disabled="readOnly"
            @change="onInputChange"
          >
            <v-radio
              v-for="option in options"
              :key="option.id"
              :value="option.value"
              :label="option.label"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12">
          <validation-provider
            slim
            v-slot="{ errors, valid }"
            :name="item.question.label"
            :rules="{
              required: isRequired,
            }"
          >
            <v-select
              :items="options"
              item-text="label"
              item-value="value"
              :label="item.question.label"
              v-model="selectedValue"
              @change="onInputChange"
              :error-messages="errors"
              :success="valid"
              :disabled="readOnly"
            >
            </v-select>
          </validation-provider>
        </v-col>
      </template>
    </template>

    <v-col cols="12" v-if="showTextInput">
      <validation-provider
        v-slot="{ errors, valid }"
        :name="item.question.label"
        rules="required"
      >
        <v-text-field
          v-model="otherValue"
          hide-details="auto"
          :error-messages="errors"
          :success="valid"
          color="primary"
          :name="item.id"
          @input="onInputChange"
          :disabled="readOnly"
          :label="item.question.label"
        >
        </v-text-field>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GroupSingleSelectInput",
  props: {
    item: Object,
    formDefinition: Object,
    value: [Array, String],
    selected: Boolean,
    readOnly: Boolean,
    isRequired: Boolean,
  },
  computed: {
    options() {
      if (this.item.question.isImage) {
        return [...this.item.question.options];
      } else {
        if (this.item.question.hasOther) {
          return [
            ...this.item.question.options,
            {
              value: "other",
              label: "Other",
            },
          ];
        } else {
          return [...this.item.question.options];
        }
      }
    },
    showTextInput() {
      return this.selectedValue === "other";
    },
  },
  data() {
    return {
      otherValue: undefined,
      selectedValue: "",
    };
  },
  methods: {
    onInputChange() {
      if (this.selectedValue === "other") {
        this.$emit("input", this.otherValue);
      } else {
        this.$emit("input", this.selectedValue);
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        const values = this.options.map((o) => o.value);
        if (val && !values.includes(val)) {
          this.otherValue = val;
          this.selectedValue = "other";
        } else {
          this.selectedValue = val;
        }
      },
    },
  },
};
</script>

<style scoped></style>

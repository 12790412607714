<template>
  <v-simple-table v-if="mappingsAvailable">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">ArcGIS Feature Service Field</th>
          <th class="text-left">UtiliSync Form Field</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="mapping in formDefinition.form.locate_request_settings
            .mappings"
          :key="mapping.name"
        >
          <td>
            {{ getAliasFromName(mapping.esri_field_name) }}
          </td>
          <td>
            <validation-provider
              v-slot="{ errors, valid }"
              name="UtiliSync Form Field"
            >
              <v-select
                label="UtiliSync Form Field"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="utilitySyncFormField"
                :items="filterQuestionChoices(mapping)"
                item-text="label"
                item-value="value"
                v-model="mapping.utiliSync_question_id"
                @change="onChange"
              >
              </v-select>
            </validation-provider>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { cloneDeep } from "lodash";
import questionsMixin from "@/mixins/questionsMixin";
import axios from "axios";

export default {
  name: "FeatureServiceFieldsTable",
  props: {
    value: Object,
    questionChoices: Array,
  },
  mixins: [questionsMixin],
  data() {
    return {
      formDefinition: {},
      mappings: [],
      fields: [],
    };
  },
  computed: {
    mappingsAvailable() {
      return Array.isArray(
        this.formDefinition?.form?.locate_request_settings?.mappings
      );
    },
    esriFieldTypeIntegerQuestionChoices() {
      const { questions } = this;
      const integerQuestions = questions.filter(({ question: { type } }) =>
        ["CALCULATION", "NUMBER", "SINGLE_SELECT"].includes(type)
      );
      return [
        {
          value: null,
          label: "None",
        },
        ...integerQuestions.map(({ id, number, question: { label } = {} }) => ({
          value: id,
          label: `${number} ${label}`,
        })),
      ];
    },
  },
  methods: {
    filterQuestionChoices(field) {
      if (field.type === "esriFieldTypeInteger") {
        return this.esriFieldTypeIntegerQuestionChoices;
      }
      return this.questionChoices;
    },
    onChange() {
      this.$emit("input", this.formDefinition);
    },
    getAliasFromName(name) {
      const field = this.fields.find((f) => f.name === name);
      if (field) {
        return field.alias;
      }
    },
    async getFields() {
      if (
        !this.formDefinition?.form?.locate_request_settings?.feature_service_url
      ) {
        return;
      }

      const token = this.formDefinition.form.locate_request_settings
        .is_secure_service
        ? localStorage.getItem("esri_token")
        : undefined;
      const params = {
        f: "pjson",
        token,
      };
      const {
        data: { fields = [] },
      } = await axios.get(
        this.formDefinition.form.locate_request_settings.feature_service_url,
        { params }
      );
      this.fields = fields;
    },
  },
  async beforeMount() {
    await this.getFields();
    this.formDefinition = cloneDeep(this.value);
    for (const [
      index,
    ] of this.formDefinition.form.locate_request_settings.mappings.entries()) {
      if (
        !Object.prototype.hasOwnProperty.call(
          this.formDefinition.form.locate_request_settings.mappings[index],
          "utiliSync_question_id"
        ) ||
        typeof this.formDefinition.form.locate_request_settings.mappings[index]
          .utiliSync_question_id === "undefined"
      ) {
        this.formDefinition.form.locate_request_settings.mappings[
          index
        ].utiliSync_question_id = null;
      }
    }
    this.onChange();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      async handler() {
        await this.getFields();
        for (const field of this.fields) {
          const inMapping =
            this.formDefinition.form.locate_request_settings.mappings.find(
              (m) => m.esri_field_name === field.name
            );
          if (!inMapping) {
            const { name } = field;
            this.formDefinition.form.locate_request_settings.mappings.push({
              esri_field_name: name,
            });
          }
        }
      },
    },
  },
};
</script>

<style scoped>
tr {
  line-height: 52px;
}

th {
  line-height: 16px;
}
</style>

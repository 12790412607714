var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "border-left" },
    [
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editingHtml,
              expression: "editingHtml",
            },
          ],
          staticClass: "template-editor",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-wrap justify-space-between align-center background",
            },
            [
              _c(
                "section",
                {
                  staticClass: "d-flex align-center justify-space-between px-3",
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-0 mx-0",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.editingHtml = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v(_vm._s(_vm.mdiChevronLeft)),
                      ]),
                      _vm._v(" Visual Doc Builder "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.showChangeFormDefinitionVersionDialog = true
                    },
                  },
                },
                [_vm._v(" Form Version 1 ")]
              ),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "d-flex" },
            [
              _c("TemplateCodeEditor", {
                staticClass: "overflow-y-auto flex-grow-1",
                staticStyle: { height: "auto" },
                attrs: {
                  value: _vm.templateCodeEditorValue,
                  contentHeight: _vm.contentHeight,
                },
                on: { input: _vm.onEditorInput },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editingHtml,
              expression: "!editingHtml",
            },
          ],
          staticClass: "template-editor",
          style: { width: `${_vm.contentWidth}px`, background: "#6f6f6f" },
        },
        [
          _c(
            "section",
            {
              ref: "topBar",
              staticClass: "background px-2",
              style: { background: "#fafafa" },
            },
            [
              _vm.editor
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-wrap align-center flex-grow-0",
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "180px" } },
                        [
                          _c("v-select", {
                            staticClass: "mr-2",
                            attrs: {
                              items: _vm.fontFamilyOptions,
                              "item-text": "label",
                              "item-value": "value",
                              value: _vm.fontFamilyValue,
                            },
                            on: { change: _vm.onFontFamilyChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item: { label, value } }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-0 my-0",
                                          style: {
                                            "font-family": value,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(label) + " ")]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "selection",
                                  fn: function ({ item: { label, value } }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "py-0 my-0",
                                          style: {
                                            "font-family": value,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(label) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1556489372
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "flex-basis": "50px" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.fontSizeOptions,
                              value: _vm.fontSizeValue,
                              "item-text": "label",
                              "item-value": "value",
                            },
                            on: { change: _vm.onFontSizeChange },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            disabled: !_vm.editor.can().undo(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().undo().run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(" " + _vm._s(_vm.mdiUndo) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            disabled: !_vm.editor.can().redo(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().redo().run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(" " + _vm._s(_vm.mdiRedo) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-width": 200,
                            "offset-x": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "ml-2 d-inline-block" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "main-btn",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.editor
                                                  .chain()
                                                  .focus()
                                                  .setColor(_vm.colorSelection)
                                                  .run()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  size: "20",
                                                  color: _vm.colorSelection,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.mdiFormatColorText)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "actions-btn",
                                                attrs: { icon: "" },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "12" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.mdiChevronDown)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            560231616
                          ),
                          model: {
                            value: _vm.showColorPicker,
                            callback: function ($$v) {
                              _vm.showColorPicker = $$v
                            },
                            expression: "showColorPicker",
                          },
                        },
                        [
                          _c("ColorForm", {
                            on: {
                              cancel: function ($event) {
                                _vm.showColorPicker = false
                              },
                              "color-saved": _vm.onTextColorSaved,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: { "is-active": _vm.editor.isActive("bold") },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleBold().run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatBold)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: { "is-active": _vm.editor.isActive("italic") },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleItalic().run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatItalic)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive("underline"),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().toggleUnderline().run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatUnderline)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-width": 200,
                            "offset-x": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            class: {
                                              "is-active":
                                                _vm.editor.isActive("link"),
                                            },
                                            attrs: { icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.getLink()
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "20" } },
                                          [_vm._v(_vm._s(_vm.mdiLink))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            139009916
                          ),
                          model: {
                            value: _vm.showLinkMenu,
                            callback: function ($$v) {
                              _vm.showLinkMenu = $$v
                            },
                            expression: "showLinkMenu",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pt-2 pb-3 px-4" },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Input URL" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                staticClass: "pt-1",
                                                attrs: {
                                                  label: "Input URL",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "name",
                                                },
                                                model: {
                                                  value: _vm.inputURL,
                                                  callback: function ($$v) {
                                                    _vm.inputURL = $$v
                                                  },
                                                  expression: "inputURL",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2111130779
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showLinkMenu = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { dark: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showLinkMenu = false
                                          _vm.setLink(_vm.inputURL)
                                        },
                                      },
                                    },
                                    [_vm._v(" Set URL ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.editor.isActive("link")
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor.chain().focus().unsetLink().run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiLinkOff)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v(_vm._s(_vm.mdiPowerOn)),
                      ]),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive({
                              textAlign: "left",
                            }),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("left")
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatAlignLeft)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive({
                              textAlign: "center",
                            }),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("center")
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatAlignCenter)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive({
                              textAlign: "right",
                            }),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("right")
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatAlignRight)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive({
                              textAlign: "justify",
                            }),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .setTextAlign("justify")
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatAlignJustify)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive("bulletList"),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleBulletList()
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatListBulleted)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          class: {
                            "is-active": _vm.editor.isActive("orderedList"),
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editor
                                .chain()
                                .focus()
                                .toggleOrderedList()
                                .run()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatListNumbered)),
                          ]),
                        ],
                        1
                      ),
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v(_vm._s(_vm.mdiPowerOn)),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.insertPageBreak },
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiFormatPageBreak)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-width": 200,
                            "offset-x": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "20" } },
                                          [_vm._v(_vm._s(_vm.mdiImage))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            176353993
                          ),
                          model: {
                            value: _vm.showImageLinkMenu,
                            callback: function ($$v) {
                              _vm.showImageLinkMenu = $$v
                            },
                            expression: "showImageLinkMenu",
                          },
                        },
                        [
                          _c("ImageForm", {
                            on: {
                              cancel: function ($event) {
                                _vm.showImageLinkMenu = false
                              },
                              "add-image": _vm.addImage,
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.editor.isActive("link")
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-width": 200,
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "20" } },
                                              [_vm._v(_vm._s(_vm.mdiTable))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                599903312
                              ),
                              model: {
                                value: _vm.showTableMenu,
                                callback: function ($$v) {
                                  _vm.showTableMenu = $$v
                                },
                                expression: "showTableMenu",
                              },
                            },
                            [
                              _vm.showTableMenu
                                ? _c("TableMenu", {
                                    on: {
                                      cancel: function ($event) {
                                        _vm.showTableMenu = false
                                      },
                                      "insert-table": _vm.onInsertTable,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-x": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-inline-block" },
                                      [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "20" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.mdiLightbulbOnOutline
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2766197051
                          ),
                          model: {
                            value: _vm.showLogicMenu,
                            callback: function ($$v) {
                              _vm.showLogicMenu = $$v
                            },
                            expression: "showLogicMenu",
                          },
                        },
                        [
                          _c("LogicMenu", {
                            on: {
                              "logic-choice-selected":
                                _vm.onLogicChoiceSelected,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showTableTypeButtons
                        ? _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiPowerOn)),
                          ])
                        : _vm._e(),
                      _vm.editor.can().addColumnBefore()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .addColumnBefore()
                                    .run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableColumnPlusBefore)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().addColumnAfter()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .addColumnAfter()
                                    .run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableColumnPlusAfter)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().deleteColumn()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .deleteColumn()
                                    .run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableColumnRemove)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().addRowBefore()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor
                                    .chain()
                                    .focus()
                                    .addRowBefore()
                                    .run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableRowPlusBefore)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().addRowAfter()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor.chain().focus().addRowAfter().run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableRowPlusAfter)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().deleteRow()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor.chain().focus().deleteRow().run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableRowRemove)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().deleteTable()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor.chain().focus().deleteTable().run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableRemove)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().mergeCells()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor.chain().focus().mergeCells().run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableMergeCells)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.editor.can().splitCell()
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.editor.chain().focus().splitCell().run()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v(_vm._s(_vm.mdiTableSplitCell)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTableTypeButtons
                        ? _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v(_vm._s(_vm.mdiPowerOn)),
                          ])
                        : _vm._e(),
                      _vm.showTableTypeButtons
                        ? _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "d-inline-block" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "main-btn",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onTableBorderSaved(
                                                      _vm.borderStyle
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "icon",
                                                  attrs: {
                                                    src: require(`@/assets/img/borders/border-${_vm.borderStyle}.svg`),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "actions-btn",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "12" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.mdiChevronDown)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1428518350
                              ),
                            },
                            [
                              _c("TableBorderMenu", {
                                on: {
                                  "border-style-selected":
                                    _vm.onTableBorderSaved,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTableTypeButtons
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-width": 200,
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "d-inline-block" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "main-btn",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.editor
                                                      .chain()
                                                      .focus()
                                                      .setCellAttribute(
                                                        "backgroundColor",
                                                        _vm.cellColorSelection
                                                      )
                                                      .run()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "20",
                                                      color:
                                                        _vm.cellColorSelection,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.mdiFormatColorFill
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "actions-btn",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "12" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.mdiChevronDown)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                255840692
                              ),
                              model: {
                                value: _vm.showCellColorPicker,
                                callback: function ($$v) {
                                  _vm.showCellColorPicker = $$v
                                },
                                expression: "showCellColorPicker",
                              },
                            },
                            [
                              _c("ColorForm", {
                                on: {
                                  cancel: function ($event) {
                                    _vm.showCellColorPicker = false
                                  },
                                  "color-saved": _vm.onCellBackgroundColorSaved,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showTableTypeButtons
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-width": 200,
                                "offset-x": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "20" } },
                                              [_vm._v(_vm._s(_vm.mdiCog))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4284746021
                              ),
                              model: {
                                value: _vm.showCellMenu,
                                callback: function ($$v) {
                                  _vm.showCellMenu = $$v
                                },
                                expression: "showCellMenu",
                              },
                            },
                            [
                              _c("CellPaddingForm", {
                                on: {
                                  cancel: function ($event) {
                                    _vm.showCellMenu = false
                                  },
                                  saved: _vm.onCellPaddingSaved,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "overflow-auto pa-3",
              style: {
                width: `${_vm.contentWidth}px`,
                height: `${_vm.contentHeight}px`,
              },
            },
            [
              _c(
                "div",
                {
                  style: {
                    height: `${_vm.pageSettings.pageHeight}in`,
                    width: `${_vm.pageSettings.pageWidth}in`,
                    "min-height": `${_vm.pageSettings.pageHeight}in`,
                    "min-width": `${_vm.pageSettings.pageWidth}in`,
                    margin: "0 auto",
                  },
                },
                [
                  _c(
                    "section",
                    {
                      staticClass: "white",
                      on: {
                        click: function ($event) {
                          _vm.templatePart = _vm.TEMPLATE_PART.HEADER
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openCodeEditor(
                                    _vm.TEMPLATE_PART.HEADER
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.mdiCodeTags) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-n7 pa-0 ma-0" },
                        [
                          _c("div", {
                            style: {
                              width: `${_vm.pageSettings.marginLeft}in`,
                              "border-right": "2px solid #ddffff",
                              "border-bottom": "2px solid #ddffff",
                            },
                          }),
                          _c("editor-content", {
                            staticClass: "overflow-y-auto flex-grow-1",
                            style: {
                              width: `${
                                _vm.pageSettings.pageWidth -
                                +_vm.pageSettings.marginLeft -
                                +_vm.pageSettings.marginRight
                              }in`,
                              height: `${_vm.pageSettings.marginTop}in`,
                            },
                            attrs: { editor: _vm.headerEditor },
                          }),
                          _c("div", {
                            style: {
                              width: `${_vm.pageSettings.marginRight}in`,
                              "border-left": "2px solid #ddffff",
                              "border-bottom": "2px solid #ddffff",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "section",
                    {
                      staticClass: "white flex-grow-1 d-flex flex-column",
                      on: {
                        click: function ($event) {
                          _vm.templatePart = _vm.TEMPLATE_PART.BODY
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openCodeEditor(
                                    _vm.TEMPLATE_PART.BODY
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.mdiCodeTags) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex pa-0",
                          style: {
                            "margin-top": "-29px",
                            "margin-bottom": "-9px",
                          },
                        },
                        [
                          _c("div", {
                            style: {
                              width: `${_vm.pageSettings.marginLeft}in`,
                              "min-height": `${
                                +_vm.pageSettings.pageHeight -
                                +_vm.pageSettings.marginTop -
                                +_vm.pageSettings.marginBottom
                              }in`,
                              "border-right": "2px solid #ddffff",
                            },
                          }),
                          _c("editor-content", {
                            staticClass: "overflow-y-auto flex-grow-1",
                            style: {
                              width: `${
                                _vm.pageSettings.pageWidth -
                                +_vm.pageSettings.marginLeft -
                                +_vm.pageSettings.marginRight
                              }in`,
                              "min-height": `${
                                +_vm.pageSettings.pageHeight -
                                +_vm.pageSettings.marginTop -
                                +_vm.pageSettings.marginBottom
                              }in`,
                              "border-top": "2px solid #ddffff",
                              "border-bottom": "2px solid #ddffff",
                            },
                            attrs: { editor: _vm.bodyEditor },
                          }),
                          _c("div", {
                            style: {
                              width: `${_vm.pageSettings.marginRight}in`,
                              "min-height": `${
                                +_vm.pageSettings.pageHeight -
                                +_vm.pageSettings.marginTop -
                                +_vm.pageSettings.marginBottom
                              }in`,
                              "border-left": "2px solid #ddffff",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "section",
                    {
                      staticClass: "white",
                      on: {
                        click: function ($event) {
                          _vm.templatePart = _vm.TEMPLATE_PART.FOOTER
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openCodeEditor(
                                    _vm.TEMPLATE_PART.FOOTER
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(" " + _vm._s(_vm.mdiCodeTags) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-n5 pa-0 ma-0" },
                        [
                          _c("div", {
                            style: {
                              width: `${_vm.pageSettings.marginLeft}in`,
                              "border-right": "2px solid #ddffff",
                              "border-top": "2px solid #ddffff",
                            },
                          }),
                          _c("editor-content", {
                            staticClass: "overflow-y-auto flex-grow-1",
                            style: {
                              width: `${
                                _vm.pageSettings.pageWidth -
                                +_vm.pageSettings.marginLeft -
                                +_vm.pageSettings.marginRight
                              }in`,
                              height: `${_vm.pageSettings.marginBottom}in`,
                            },
                            attrs: { editor: _vm.footerEditor },
                          }),
                          _c("div", {
                            style: {
                              width: `${_vm.pageSettings.marginRight}in`,
                              "border-left": "2px solid #ddffff",
                              "border-top": "2px solid #ddffff",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("p", { staticClass: "caption" }, [
            _vm._v(_vm._s(_vm.placeholder)),
          ]),
          Array.isArray(_vm.errors)
            ? _c("p", { staticClass: "caption red--text" }, [
                _vm._v(" " + _vm._s(_vm.errors[0]) + " "),
              ])
            : _vm._e(),
          _vm.showInsertMultipleImagesDialog
            ? _c("InsertMultipleImagesDialog", {
                attrs: {
                  showInsertMultipleImagesDialog:
                    _vm.showInsertMultipleImagesDialog,
                },
                on: {
                  "close-insert-images-dialog": function ($event) {
                    _vm.showInsertMultipleImagesDialog = false
                  },
                  "insert-multiple-image": _vm.insertMultipleImages,
                },
              })
            : _vm._e(),
          _vm.showPhotoSizeDialog
            ? _c("PhotoSizeDialog", {
                attrs: {
                  showPhotoSizeDialog: _vm.showPhotoSizeDialog,
                  selectedFormField: _vm.selectedFormField,
                },
                on: {
                  "close-photo-size-dialog": function ($event) {
                    _vm.showPhotoSizeDialog = false
                  },
                  "insert-image": _vm.onInsertImage,
                },
              })
            : _vm._e(),
          _vm.showStatementDialog
            ? _c("StatementDialog", {
                attrs: {
                  showStatementDialog: _vm.showStatementDialog,
                  selectedLogicStatement: _vm.selectedLogicStatement,
                  formDefinition: _vm.formDefinition,
                },
                on: {
                  "close-statement-dialog": function ($event) {
                    _vm.showStatementDialog = false
                  },
                  "statement-saved": _vm.onStatementSaved,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("ChangeFormDefinitionVersionDialog", {
        attrs: {
          showChangeFormDefinitionVersionDialog:
            _vm.showChangeFormDefinitionVersionDialog,
          versionNumber: 2,
        },
        on: {
          cancel: function ($event) {
            _vm.showChangeFormDefinitionVersionDialog = false
          },
          "change-form-version": _vm.onChangeFormVersion,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
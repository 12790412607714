<template>
  <section class="d-flex flex-column my-0 py-3">
    <v-switch
      v-model="formDefinition.form.formDescription.isDocumentEnabled"
      label="Enable PDF"
      @change="$emit('input', formDefinition)"
      :disabled="disabled"
      hide-details
      class="py-0 my-0"
    >
    </v-switch>

    <v-btn text color="primary" @click="showPreviewTemplateDialog = true">
      View Sample PDF
    </v-btn>

    <validation-provider
      name="File Name"
      :rules="{
        required: formDefinition.form.formDescription.isDocumentEnabled,
      }"
      v-slot="{ errors, valid }"
    >
      <v-text-field
        placeholder="File Name"
        :label="formNameLabel"
        hide-details="auto"
        :error-messages="errors"
        :success="valid"
        color="primary"
        name="value"
        v-model="formDefinition.form.formDescription.pdfFileName"
        @input="$emit('input', formDefinition)"
        :disabled="disabled"
        persistent-hint
        :hint="NAME_HINT"
      >
        <template #append>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ mdiPlus }} </v-icon>
              </v-btn>
            </template>

            <v-list v-if="textFormFields.length > 0">
              <v-list-item
                v-for="f of textFormFields"
                :key="f.id"
                class="body-1 px-4 my-0 list-background"
                @click="updateFileName(f.id)"
              >
                ID:{{ f.id }} - {{ f.question.label }}
              </v-list-item>
            </v-list>
            <div v-else class="pa-3 list-background">No Applicable Fields</div>
          </v-menu>
        </template>
      </v-text-field>
    </validation-provider>

    <validation-provider name="Folder" v-slot="{ errors, valid }">
      <v-text-field
        placeholder="Folder"
        label="Folder"
        hide-details="auto"
        :error-messages="errors"
        :success="valid"
        color="primary"
        name="value"
        v-model="formDefinition.folder"
        @input="$emit('input', formDefinition)"
        :disabled="disabled"
        persistent-hint
        :hint="FOLDER_HINT"
      >
        <template #append>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ mdiPlus }} </v-icon>
              </v-btn>
            </template>

            <v-list max-height="95vh" v-if="textFormFields.length > 0">
              <v-list-item
                v-for="f of textFormFields"
                :key="f.id"
                class="body-1 px-4 my-0 list-background"
                @click="updateFolderName(f.id)"
              >
                ID:{{ f.id }} - {{ f.question.label }}
              </v-list-item>
            </v-list>
            <div v-else class="pa-3 list-background">No Applicable Fields</div>
          </v-menu>
        </template>
      </v-text-field>
    </validation-provider>

    <v-btn
      text
      color="primary"
      @click="showPageSettingsDialog = true"
      v-if="showPageSettings"
    >
      Page Settings
    </v-btn>

    <PreviewTemplateDialog
      v-if="showPreviewTemplateDialog"
      :showPreviewTemplateDialog="showPreviewTemplateDialog"
      :formDefinition="value"
      @preview-template-dialog-close="showPreviewTemplateDialog = false"
    />

    <PageSettingsDialog
      :showPageSettingsDialog="showPageSettingsDialog"
      v-if="showPageSettingsDialog"
      :value="value"
      @close-doc-settings-dialog="showPageSettingsDialog = false"
      @input="onInputDocSettings"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import { FIELD_TYPES } from "@/constants/question";
import { mdiPlus, mdiDotsVertical } from "@mdi/js";
import PreviewTemplateDialog from "@/components/forms/form-builder-edit-form/report-template-form/PreviewTemplateDialog.vue";
import PageSettingsDialog from "@/components/forms/form-builder-edit-form/report-template-form/edit-template/form-configuration-form/PageSettingsDialog";

const NAME_HINT = "Example: SWPPP Report {{params.3}}";
const FOLDER_HINT = "Example: /Field Reports/SWPPP Reports";

export default {
  name: "FormConfigurationForm",
  props: {
    value: Object,
    disabled: Boolean,
    showPageSettings: { type: Boolean, default: true },
  },
  components: { PreviewTemplateDialog, PageSettingsDialog },
  data() {
    return {
      formDefinition: {},
      NAME_HINT,
      FOLDER_HINT,
      mdiPlus,
      mdiDotsVertical,
      showPreviewTemplateDialog: false,
      showPageSettingsDialog: false,
    };
  },
  computed: {
    formNameLabel() {
      if (this.formDefinition.form.formDescription.isDocumentEnabled) {
        return "Form Name *";
      } else {
        return "Form Name";
      }
    },
    textFormFields() {
      return this.formDefinition.form.sections
        .map((s) => s.items)
        .flat()
        .filter(
          (item) =>
            item.type === "QUESTION" &&
            [
              FIELD_TYPES.TEXT,
              FIELD_TYPES.EMAIL,
              FIELD_TYPES.NUMBER,
              FIELD_TYPES.SINGLE_SELECT,
              FIELD_TYPES.MULTI_SELECT,
              FIELD_TYPES.DATE,
              FIELD_TYPES.TIME,
            ].includes(item?.question?.type)
        );
    },
  },
  methods: {
    onInputDocSettings(formDefinition) {
      this.$emit("input", formDefinition);
      this.showPageSettingsDialog = false;
    },
    updateFileName(formFieldId) {
      if (this.formDefinition.form.formDescription.pdfFileName) {
        this.formDefinition.form.formDescription.pdfFileName = `${this.formDefinition.form.formDescription.pdfFileName} {{params.${formFieldId}}}`;
      } else {
        this.formDefinition.form.formDescription.pdfFileName = `{{params.${formFieldId}}}`;
      }
      this.$emit("input", this.formDefinition);
    },
    updateFolderName(formFieldId) {
      if (this.formDefinition.folder) {
        this.formDefinition.folder = `${this.formDefinition.folder} {{params.${formFieldId}}}`;
      } else {
        this.formDefinition.folder = `{{params.${formFieldId}}}`;
      }
      this.$emit("input", this.formDefinition);
    },
  },
  beforeMount() {
    this.formDefinition = cloneDeep(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
      },
    },
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasFormFieldInsertedCount
        ? _c(
            "v-btn",
            {
              staticClass: "px-0 mx-0",
              attrs: { text: "", color: "primary" },
              on: {
                click: function ($event) {
                  _vm.showInsertedInDoc = !_vm.showInsertedInDoc
                },
              },
            },
            [_vm._v(" Show Inserted in Doc ")]
          )
        : _vm._e(),
      _c("validation-provider", {
        attrs: { name: "Search" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors, valid }) {
              return [
                _c("v-text-field", {
                  attrs: {
                    label: "Search",
                    "hide-details": "auto",
                    "error-messages": errors,
                    success: valid,
                    color: "primary",
                    name: "searchField",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("v-icon", [_vm._v(_vm._s(_vm.mdiMagnify))]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.searchField,
                    callback: function ($$v) {
                      _vm.searchField = $$v
                    },
                    expression: "searchField",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-list",
        { staticClass: "pa-0 my-0" },
        _vm._l(_vm.filteredFormFields, function (f) {
          return _c(
            "v-list-item",
            {
              key: f.id,
              staticClass: "pa-0 my-0",
              on: {
                click: function ($event) {
                  return _vm.$emit("form-field-selected", f.id)
                },
              },
            },
            [
              _c("v-list-item-content", { staticClass: "pa-0 my-0" }, [
                _vm.showInsertedInDoc
                  ? _c(
                      "div",
                      {
                        staticClass: "caption",
                        class: {
                          "primary--text": _vm.formFieldInsertedCount[f.id] > 0,
                          "yellow--text text--darken-4":
                            (_vm.formFieldInsertedCount[f.id] || 0) === 0,
                        },
                      },
                      [
                        _vm._v(
                          " Inserted in Doc " +
                            _vm._s(_vm.formFieldInsertedCount[f.id] || 0) +
                            " Times "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", [
                  _vm._v(
                    "ID:" + _vm._s(f.id) + " - " + _vm._s(f.question.label)
                  ),
                ]),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
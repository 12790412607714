var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2" },
    [
      _c("validation-observer", {
        ref: "createCityworksActivity",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.createActivity.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary" },
                      },
                      [
                        _c("v-toolbar-title", [
                          _vm._v("New Cityworks Activity"),
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "create-cityworks-activity-close"
                                )
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass: "pl-8 pr-8 pb-0",
                        style: {
                          "background-color": "#f1f2f1",
                          height: _vm.$vuetify.breakpoint.xsOnly
                            ? `${_vm.contentHeight}px`
                            : "70vh",
                          "overflow-y": "auto",
                          "max-height": _vm.$vuetify.breakpoint.xsOnly
                            ? undefined
                            : "60vh",
                        },
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "3" } },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Create New Activity",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  errors,
                                                  valid,
                                                }) {
                                                  return [
                                                    _c("v-select", {
                                                      staticClass: "py-0 my-0",
                                                      attrs: {
                                                        label:
                                                          "Create New Activity",
                                                        "hide-details": "auto",
                                                        "error-messages":
                                                          errors,
                                                        success: valid,
                                                        color: "primary",
                                                        name: "createNewActivity",
                                                        items:
                                                          _vm.activityOptions,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.createCondition,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.createCondition =
                                                            $$v
                                                        },
                                                        expression:
                                                          "createCondition",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.createCondition === "CONDITIONAL"
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Form Field",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "py-0 my-0",
                                                          attrs: {
                                                            label: "Form Field",
                                                            "hide-details":
                                                              "auto",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            color: "primary",
                                                            name: "formField",
                                                            items:
                                                              _vm.questionChoices,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.questionID,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.questionID =
                                                                $$v
                                                            },
                                                            expression:
                                                              "questionID",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.createCondition === "CONDITIONAL"
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Condition",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "py-0 my-0",
                                                          attrs: {
                                                            label: "Condition",
                                                            "hide-details":
                                                              "auto",
                                                            "error-messages":
                                                              errors,
                                                            success: valid,
                                                            color: "primary",
                                                            name: "questionCondition",
                                                            items:
                                                              _vm.questionConditionOptions,
                                                            "item-text":
                                                              "label",
                                                            "item-value":
                                                              "value",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.conditionOperator,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.conditionOperator =
                                                                $$v
                                                            },
                                                            expression:
                                                              "conditionOperator",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.createCondition === "CONDITIONAL"
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Value",
                                                rules: {
                                                  required: true,
                                                  numeric:
                                                    _vm.questionType ===
                                                    "NUMBER",
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      errors,
                                                      valid,
                                                    }) {
                                                      return [
                                                        _vm.questionType !==
                                                        "SINGLE_SELECT"
                                                          ? _c("v-text-field", {
                                                              staticClass:
                                                                "py-0 my-0",
                                                              attrs: {
                                                                label: "Value",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                type:
                                                                  _vm.questionType ===
                                                                  "NUMBER"
                                                                    ? "number"
                                                                    : "",
                                                                color:
                                                                  "primary",
                                                                name: "value",
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.conditionValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.conditionValue =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "conditionValue",
                                                              },
                                                            })
                                                          : _c("v-select", {
                                                              staticClass:
                                                                "py-0 my-0",
                                                              attrs: {
                                                                label: "Value",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                color:
                                                                  "primary",
                                                                name: "value",
                                                                "item-text":
                                                                  "label",
                                                                "item-value":
                                                                  "value",
                                                                items:
                                                                  _vm.singleSelectOptions,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.conditionValue,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.conditionValue =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "conditionValue",
                                                              },
                                                            }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                class:
                                  _vm.createCondition === "CONDITIONAL"
                                    ? "mt-n4"
                                    : "",
                                attrs: { cols: "12" },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Activity Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Activity Type",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "activityType",
                                                items: _vm.activityTypes,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value: _vm.cwType,
                                                callback: function ($$v) {
                                                  _vm.cwType = $$v
                                                },
                                                expression: "cwType",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Domain", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Domain",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "domain",
                                                items: _vm.domainChoices,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onChange("domain")
                                                },
                                              },
                                              model: {
                                                value: _vm.cwDomain,
                                                callback: function ($$v) {
                                                  _vm.cwDomain = $$v
                                                },
                                                expression: "cwDomain",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Entity Group",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Entity Group",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "entityGroup",
                                                items: _vm.entityGroupChoices,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onChange(
                                                    "entity_group"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.cwEntityGroup,
                                                callback: function ($$v) {
                                                  _vm.cwEntityGroup = $$v
                                                },
                                                expression: "cwEntityGroup",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Entity Type",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Entity Type",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "entityType",
                                                items: _vm.entityTypeChoices,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onChange(
                                                    "entity_type"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.cwEntityType,
                                                callback: function ($$v) {
                                                  _vm.cwEntityType = $$v
                                                },
                                                expression: "cwEntityType",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Template",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "Template",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                name: "templateId",
                                                items: _vm.templateChoices,
                                                "item-text": "label",
                                                "item-value": "value",
                                              },
                                              model: {
                                                value: _vm.cwTemplate,
                                                callback: function ($$v) {
                                                  _vm.cwTemplate = $$v
                                                },
                                                expression: "cwTemplate",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("section"),
                              ],
                              1
                            ),
                            _vm.cwType === "workorder"
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Work Order Instructions",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Instructions",
                                                    "hide-details": "auto",
                                                    "error-messages": errors,
                                                    success: valid,
                                                    color: "primary",
                                                    name: "value",
                                                  },
                                                  model: {
                                                    value: _vm.cwInstructions,
                                                    callback: function ($$v) {
                                                      _vm.cwInstructions = $$v
                                                    },
                                                    expression:
                                                      "cwInstructions",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.formDefinition.form.city_works_webhook_config
                              .type === "Inspection" &&
                            _vm.cwType === "workorder"
                              ? _c(
                                  "div",
                                  { staticClass: "pl-3" },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        label: "Attach Current Activity",
                                      },
                                      model: {
                                        value: _vm.attachCurrentActivity,
                                        callback: function ($$v) {
                                          _vm.attachCurrentActivity = $$v
                                        },
                                        expression: "attachCurrentActivity",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      {
                        ref: "cardAction",
                        staticClass: "d-flex justify-end py-3 px-7",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              type: "submit",
                              disabled: invalid,
                            },
                          },
                          [_vm._v("Save Cityworks Activity")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          attrs: { multiple: "", accordion: "" },
          model: {
            value: _vm.openItems,
            callback: function ($$v) {
              _vm.openItems = $$v
            },
            expression: "openItems",
          },
        },
        [
          _c(
            "draggable",
            {
              staticClass: "list-group",
              attrs: { group: "repeating-group-items", handle: ".handle" },
              on: { end: _vm.assignNumbersToItems },
              model: {
                value: _vm.repeatingGroupItems,
                callback: function ($$v) {
                  _vm.repeatingGroupItems = $$v
                },
                expression: "repeatingGroupItems",
              },
            },
            _vm._l(_vm.repeatingGroupItems, function (r, index) {
              return _c(
                "v-expansion-panel",
                { key: r.id },
                [
                  _c("v-expansion-panel-header", { staticClass: "mx-0 px-0" }, [
                    _c(
                      "section",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-icon",
                              { staticClass: "handle cursor-pointer" },
                              [_vm._v(" " + _vm._s(_vm.mdiDragVertical) + " ")]
                            ),
                            r.question
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("itemTitle")(
                                          _vm.getQuestionType(r),
                                          r
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              : _vm._e(),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("itemTitle")(_vm.getInfoType(r), r)
                                  ) +
                                  " "
                              ),
                            ],
                          ],
                          2
                        ),
                        _c(
                          "v-menu",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "cursor-pointer",
                                              attrs: { icon: "" },
                                            },
                                            "div",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiDotsVertical)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: _vm.onRemoveRepeatingGroupItem,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.mdiDelete)),
                                        ]),
                                        _vm._v(" Delete "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "mx-0 px-0", attrs: { eager: "" } },
                    [
                      ["DATE", "TIME"].includes(_vm.getQuestionType(r))
                        ? _c("DateTimeForm", {
                            key: `${r.id}-${index}`,
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              layer: _vm.layer,
                              gisDataFields: _vm.gisDataFields,
                              userDataFields: _vm.userDataFields,
                              fields: _vm.fields,
                              type: _vm.getQuestionType(r),
                            },
                            on: {
                              "remove-repeating-group-item":
                                _vm.onRemoveRepeatingGroupItem,
                              input: function ($event) {
                                return _vm.$emit(
                                  "input",
                                  _vm.repeatingGroupItems
                                )
                              },
                            },
                            model: {
                              value: _vm.repeatingGroupItems[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.repeatingGroupItems, index, $$v)
                              },
                              expression: "repeatingGroupItems[index]",
                            },
                          })
                        : ["FILE"].includes(_vm.getQuestionType(r))
                        ? [
                            !r.question.isImage
                              ? _c("FileForm", {
                                  key: `${r.id}-${index}`,
                                  on: {
                                    "remove-repeating-group-item":
                                      _vm.onRemoveRepeatingGroupItem,
                                    input: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.repeatingGroupItems
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.repeatingGroupItems[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.repeatingGroupItems,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression: "repeatingGroupItems[index]",
                                  },
                                })
                              : _c("PhotoForm", {
                                  key: `${r.id}-${index}`,
                                  on: {
                                    "remove-repeating-group-item":
                                      _vm.onRemoveRepeatingGroupItem,
                                    input: function ($event) {
                                      return _vm.$emit(
                                        "input",
                                        _vm.repeatingGroupItems
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.repeatingGroupItems[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.repeatingGroupItems,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression: "repeatingGroupItems[index]",
                                  },
                                }),
                          ]
                        : ["SINGLE_SELECT", "MULTI_SELECT"].includes(
                            _vm.getQuestionType(r)
                          )
                        ? _c("SelectForm", {
                            key: `${r.id}-${index}`,
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              layer: _vm.layer,
                              gisDataFields: _vm.gisDataFields,
                              userDataFields: _vm.userDataFields,
                              fields: _vm.fields,
                            },
                            on: {
                              "remove-repeating-group-item":
                                _vm.onRemoveRepeatingGroupItem,
                              input: function ($event) {
                                return _vm.$emit(
                                  "input",
                                  _vm.repeatingGroupItems
                                )
                              },
                            },
                            model: {
                              value: _vm.repeatingGroupItems[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.repeatingGroupItems, index, $$v)
                              },
                              expression: "repeatingGroupItems[index]",
                            },
                          })
                        : ["SIGNATURE"].includes(_vm.getQuestionType(r))
                        ? _c("SignatureForm", {
                            key: `${r.id}-${index}`,
                            on: {
                              "remove-repeating-group-item":
                                _vm.onRemoveRepeatingGroupItem,
                              input: function ($event) {
                                return _vm.$emit(
                                  "input",
                                  _vm.repeatingGroupItems
                                )
                              },
                            },
                            model: {
                              value: _vm.repeatingGroupItems[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.repeatingGroupItems, index, $$v)
                              },
                              expression: "repeatingGroupItems[index]",
                            },
                          })
                        : ["TEXT", "EMAIL", "NUMBER"].includes(
                            _vm.getQuestionType(r)
                          )
                        ? _c("TextForm", {
                            key: `${r.id}-${index}`,
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              layer: _vm.layer,
                              gisDataFields: _vm.gisDataFields,
                              userDataFields: _vm.userDataFields,
                              fields: _vm.fields,
                            },
                            on: {
                              "remove-repeating-group-item":
                                _vm.onRemoveRepeatingGroupItem,
                              input: function ($event) {
                                return _vm.$emit(
                                  "input",
                                  _vm.repeatingGroupItems
                                )
                              },
                            },
                            model: {
                              value: _vm.repeatingGroupItems[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.repeatingGroupItems, index, $$v)
                              },
                              expression: "repeatingGroupItems[index]",
                            },
                          })
                        : ["HEADER", "URL", "TEXT", "IMAGE"].includes(
                            _vm.getInfoType(r)
                          )
                        ? _c("InformationForm", {
                            key: `${r.id}-${index}`,
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              layer: _vm.layer,
                              gisDataFields: _vm.gisDataFields,
                              userDataFields: _vm.userDataFields,
                              fields: _vm.fields,
                              selectedItemOptions: _vm.selectedItemOptions,
                            },
                            on: {
                              "remove-repeating-group-item":
                                _vm.onRemoveRepeatingGroupItem,
                              input: function ($event) {
                                return _vm.$emit(
                                  "input",
                                  _vm.repeatingGroupItems
                                )
                              },
                            },
                            model: {
                              value: _vm.repeatingGroupItems[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.repeatingGroupItems, index, $$v)
                              },
                              expression: "repeatingGroupItems[index]",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { CONDITION_CHOICES } from "@/constants/advancedSettings";

export const standardQuestionTypes = [
  { title: "Singleline Text", icon: "mdi-form-textbox" },
  { title: "Paragraph", icon: "mdi-text" },
  { title: "Email", icon: "mdi-email" },
  { title: "Date", icon: "mdi-calendar" },
  { title: "Time", icon: "mdi-clock-outline" },
  { title: "Number", icon: "mdi-numeric" },
];

export const advancedQuestionTypes = [
  { title: "Positive Response", icon: "mdi-send-circle" },
  { title: "Single Select", icon: "mdi-format-list-checks" },
  { title: "Multiple Select", icon: "mdi-format-list-checkbox" },
  { title: "Photo", icon: "mdi-image-multiple" },
  { title: "File", icon: "mdi-file" },
  { title: "Signature", icon: "mdi-signature-freehand" },
  { title: "Calculation", icon: "mdi-calculator" },
  { title: "Action Item", icon: "mdi-checkbox-marked" },
  { title: "Table", icon: "mdi-table" },
];

export const itemTypes = [
  { title: "Header Info", icon: "mdi-format-title" },
  { title: "Web URL", icon: "mdi-link" },
  { title: "Text Info", icon: "mdi-clipboard-text" },
  { title: "Info Image", icon: "mdi-image" },
];

export const repeatingGroupQuestionTypes = [
  { title: "Singleline Text" },
  { title: "Paragraph" },
  { title: "Email" },
  { title: "Date" },
  { title: "Time" },
  { title: "Number" },
  { title: "Single Select" },
  { title: "Multiple Select" },
  { title: "Photo" },
  { title: "File" },
  { title: "Signature" },
];

export const repeatingGroupInfoTypes = [
  { title: "Header Info" },
  { title: "Web URL" },
  { title: "Text Info" },
  { title: "Info Image" },
];

export const formDefinition = {
  form: {
    cityworks: {
      close_work_order: {
        condition: CONDITION_CHOICES.NEVER,
      },
    },
    emailSettings: [],
    emails: [],
    eventSettings: {
      condition: {
        condition: CONDITION_CHOICES.NEVER,
      },
      duration: 30,
    },
    formDescription: {
      canEditAfterFinal: false,
      description: "",
      isAccordionDisplay: true,
      isDocumentEnabled: false,
      title: "Untitled Form",
    },
    gisFieldsToUpdate: [],
    sections: [
      {
        id: 1,
        editing: false,
        hideHeader: false,
        isOpenAdmin: true,
        isOpenByDefault: true,
        items: [],
        name: "Untitled Section",
        numbered: false,
      },
    ],
  },
};

export const newSection = {
  id: 1,
  editing: false,
  hideHeader: false,
  isOpenAdmin: true,
  isOpenByDefault: false,
  items: [],
  name: "Untitled Section",
  numbered: false,
  visible: {
    condition: CONDITION_CHOICES.ALWAYS,
    dependantId: undefined,
    value: undefined,
  },
};

export const types = {
  "Singleline Text": "TEXT",
  Email: "EMAIL",
  Number: "NUMBER",
  "Single Select": "SINGLE_SELECT",
  "Multiple Select": "MULTI_SELECT",
  Date: "DATE",
  Time: "TIME",
};

export const getItemByType = (type, formDefinition) => {
  const { sections } = formDefinition.form;
  const sectionItems = [...sections.map((s) => s.items)].flat();
  const itemIds = sectionItems.map((item) => item.id);
  const id = sectionItems.length === 0 ? 1 : Math.max(...itemIds) + 1;
  if (["Singleline Text", "Email", "Number", "Paragraph"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        isLong: type === "Paragraph",
        default: {
          type: null,
          value: "",
        },
        label: "",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: type === "Paragraph" ? "TEXT" : types[type],
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "QUESTION",
    };
  } else if (["Single Select", "Multiple Select"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        hasOther: false,
        default: {
          type: null,
          value: "",
        },
        label: "",
        options: [
          {
            label: `${
              type === "Multiple Select" ? "Multi" : "Single"
            } Select #1`,
            value: `${
              type === "Multiple Select" ? "Multi" : "Single"
            } Select #1`,
          },
        ],
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: types[type],
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
        showAllOptions: true,
        hint: "",
      },
      type: "QUESTION",
    };
  } else if (["Positive Response"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        default: {
          type: null,
          value: "",
        },
        label: "Positive Response",
        positiveResponses: [
          // fields in each object in this array includes label, showCode and visible
        ],
        comments: [],
        options: [],
        required: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
        type: "POSITIVE_RESPONSE",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
        showAllOptions: true,
        locateRequestProviderId: null,
        showAllComments: false,
      },
      type: "QUESTION",
    };
  } else if (["Date", "Time"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        default: {
          type: null,
          value: null,
        },
        label: "",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: types[type],
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "QUESTION",
    };
  } else if (["Photo", "File"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        default: {
          type: null,
          value: null,
        },
        isImage: type === "Photo",
        label: "",
        maxWidthHeight: null,
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        showDescription: true,
        type: "FILE",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "QUESTION",
    };
  } else if (["Signature"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        default: {
          type: null,
          value: null,
        },
        label: "",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: "SIGNATURE",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "QUESTION",
    };
  } else if (["Calculation"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        default: {
          type: null,
          value: null,
        },
        calculation: "0",
        label: "",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: "CALCULATION",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
        watchIds: [6],
      },
      type: "QUESTION",
    };
  } else if (["Action Item"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      isVisible: true,
      itemNumber: 0,
      number: "",
      question: {
        autoLoadActionItems: true,
        descriptionHint: "",
        isCurrentlyRequired: false,
        isInvalid: false,
        label: "Action Items",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: "ACTION_ITEM",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
        populateEmailWithIds: [],
      },
      type: "QUESTION",
      value: {
        newActionItems: [],
        openActionItems: [],
      },
    };
  } else if (["Table"].includes(type)) {
    return {
      allowMultiple: false,
      id,
      number: "",
      question: {
        groupedItems: [],
        label: "",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        type: "GROUP",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "QUESTION",
      value: [],
    };
  } else if (["Header Info"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "HEADER",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  } else if (["Web URL"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "URL",
        url: "",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  } else if (["Text Info"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "TEXT",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  } else if (["Info Image"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "IMAGE",
        url: "",
        openUrl: "",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
          applyVisible: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  }
};

export const getRepeatingGroupItemByType = (type, items) => {
  const itemIds = items.map((item) => item.id);
  const id = items.length === 0 ? 1 : Math.max(...itemIds) + 1;
  if (["Singleline Text", "Paragraph"].includes(type)) {
    return {
      id,
      question: {
        isLong: type === "Paragraph",
        default: {
          type: null,
          value: "",
        },
        label: `Untitled ${type}`,
        placeholder: "",
        type: "TEXT",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
    };
  } else if (["Email"].includes(type)) {
    return {
      id,
      question: {
        label: `Untitled ${type}`,
        type: "EMAIL",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
    };
  } else if (["Date", "Time", "Number"].includes(type)) {
    const types = {
      Date: "DATE",
      Time: "TIME",
      Number: "NUMBER",
    };
    return {
      id,
      question: {
        default: {
          type: ["Date", "Time"].includes(type) ? "CURRENT" : null,
          value: "",
        },
        label: `Untitled ${type}`,
        type: types[type],
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
    };
  } else if (["Single Select", "Multiple Select"].includes(type)) {
    const types = {
      "Single Select": "SINGLE_SELECT",
      "Multiple Select": "MULTI_SELECT",
    };
    return {
      id,
      question: {
        default: {
          type: null,
          value: "",
        },
        label: `Untitled ${type}`,
        options: [],
        type: types[type],
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
        showAllOptions: true,
      },
    };
  } else if (["File"].includes(type)) {
    return {
      id,
      question: {
        label: `Untitled ${type}`,
        showDescription: true,
        type: "FILE",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
    };
  } else if (["Photo"].includes(type)) {
    return {
      id,
      question: {
        imagesPerPage: 2,
        isImage: true,
        label: `Untitled ${type}`,
        maxWidthHeight: "600",
        showDescription: true,
        type: "FILE",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
    };
  } else if (["Signature"].includes(type)) {
    return {
      id,
      question: {
        label: `Untitled ${type}`,
        type: "SIGNATURE",
        required: {
          condition: CONDITION_CHOICES.NEVER,
        },
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
    };
  } else if (["Header Info"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "HEADER",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  } else if (["Web URL"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "URL",
        url: "",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  } else if (["Text Info"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "TEXT",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  } else if (["Info Image"].includes(type)) {
    return {
      id,
      information: {
        label: "",
        type: "IMAGE",
        url: "",
        openUrl: "",
        visible: {
          condition: CONDITION_CHOICES.ALWAYS,
        },
      },
      type: "INFORMATION",
    };
  }
};

export const FIELD_TYPES = {
  TEXT: "TEXT",
  EMAIL: "EMAIL",
  NUMBER: "NUMBER",
  SINGLE_SELECT: "SINGLE_SELECT",
  MULTI_SELECT: "MULTI_SELECT",
  DATE: "DATE",
  TIME: "TIME",
  SIGNATURE: "SIGNATURE",
  GROUP: "GROUP",
  CALCULATION: "CALCULATION",
  ACTION_ITEM: "ACTION_ITEM",
  FILE: "FILE",
  POSITIVE_RESPONSE: "POSITIVE_RESPONSE",
};
